import {connect} from 'react-redux';
import getReleasesSoon from 'app/actions/ReleasesSoonActions';
import {resetReleaseItemState} from 'app/actions/ReleaseActions';
import {getIsFetching, getReleasesSoonSelector} from 'app/selectors/ReleasesSoonSelectors';
import {getSoonPageIsEnabled, getCurrentCityId, getRoutePrefix} from 'app/selectors/AppSelectors';
import ReleasesSoonComponent from 'app/components/ReleasesSoonComponent';

const mapStateToProps = state => ({
	isFetching: getIsFetching(state),
	releasesSoon: getReleasesSoonSelector(state),
	soonPageIsEnabled: getSoonPageIsEnabled(state),
	cityId: getCurrentCityId(state),
	prefix: getRoutePrefix(state)
});

export default connect(mapStateToProps, {getReleasesSoon, resetReleaseItemState})(ReleasesSoonComponent);
