import { createAction } from 'redux-actions';
import httpRequestPromise from 'app/httpRequestPromise';
import * as actionsTypes from 'app/constants/AppConstants';
import { KINOKASSA_API_URL } from 'app/constants/SettingsConstants';
import { ReduxTypes } from 'app/store/redux';

const getReleasesSoonRequest = createAction(
	actionsTypes.GET_RELEASES_SOON_REQUEST,
);

const getReleasesSoonSuccess = createAction(
	actionsTypes.GET_RELEASES_SOON_SUCCESS,
	payload => (payload),
);

const getReleasesSoonFail = createAction(
	actionsTypes.GET_RELEASES_SOON_FAIL,
	error => ({error}),
);

const getReleasesSoon = (cityId: number): ReduxTypes.ThunkResult<void> => async (dispatch) => {
	dispatch(getReleasesSoonRequest());

	try {
		const {
			countries, formats, genres, qualifiers, releases: releasesSoon,
		} = await httpRequestPromise(`${KINOKASSA_API_URL}/api/v2/release/soon?city_id=${cityId}`, 'GET');

		dispatch(getReleasesSoonSuccess({countries, formats, genres, qualifiers, releasesSoon}));
	} catch (error) {
		dispatch(getReleasesSoonFail(error));
	}
};

export default getReleasesSoon;
