import React from 'react';
import PropTypes from 'prop-types';
import cssVars from 'css-vars-ponyfill';
import KeydownEnterButton from 'app/components/KeydownEnterButtonComponent';
import { FaCog } from "@react-icons/all-files/fa/FaCog";
import { FaEyeSlash } from "@react-icons/all-files/fa/FaEyeSlash";

export default class ViewComponent extends React.Component {
	static propTypes = {
		fontSize: PropTypes.number.isRequired,
		setSemiblind: PropTypes.func.isRequired,
		setFontSize: PropTypes.func.isRequired
	};

	componentDidMount() {
		this.applySettings();
		this.applyFontSize();
	}

	componentDidUpdate(prevProps) {
		if (prevProps.fontSize !== this.props.fontSize) {
			this.applyFontSize();
		}
	}

	handleSetSetting = ({target}) => {
		const {settingType, settingValue} = target.dataset;

		localStorage.setItem(settingType, settingValue);
		this.applySettings();
	}

	handleChangeFontSize = ({currentTarget}) => {
		const fontDiff = Number(currentTarget.value);
		const {fontSize, setFontSize} = this.props;
		const size = fontSize + fontDiff;

		localStorage.setItem('fontSize', size);
		setFontSize(size);
	}

	applySettings = () => {
		const theme = localStorage.getItem('colorTheme');
		const images = localStorage.getItem('showImages');
		const font = localStorage.getItem('fontType');
		const line = localStorage.getItem('lineHeight');
		const letter = localStorage.getItem('letterSpacing');

		const body = document.getElementsByTagName('body')[0];
		body.className = `${theme} ${images} ${font} ${line} ${letter}`;
	}

	applyFontSize = () => {
		cssVars({variables: {'fontSize': `${this.props.fontSize}px`}});
	}

	handleDefaultSettings = () => {
		localStorage.setItem('colorTheme', 'blackOnWhite');
		localStorage.setItem('showImages', 'imageOn');
		localStorage.setItem('fontSize', '16');
		localStorage.setItem('fontType', 'noSerif');
		localStorage.setItem('lineHeight', 'line_1_5');
		localStorage.setItem('letterSpacing', 'letter-spacing1');

		this.applySettings();
		this.props.setFontSize(16);
	}

	handleBasicView = () => {
		const {setSemiblind, setFontSize} = this.props;

		localStorage.removeItem('colorTheme');
		localStorage.removeItem('fontSize');
		setSemiblind(false);
		setFontSize(16);

		const body = document.getElementsByTagName('body')[0];
		body.className = '';
		cssVars({variables: {'fontSize': '16px'}});
	}

	render() {
		return (
			<section className="view-settings">
				<div className="view-settings__row">
					<div className="view-settings__block">
						<p>{i18n.t('FontSize')}</p>
						<KeydownEnterButton
							value={-2}
							onClick={this.handleChangeFontSize}
						>
							A-
						</KeydownEnterButton>
						<KeydownEnterButton
							value={2}
							onClick={this.handleChangeFontSize}
						>
							<strong>A+</strong>
						</KeydownEnterButton>
					</div>
					<div className="view-settings__block">
						<p>{i18n.t('Pictures')}:</p>
						<KeydownEnterButton
							className="btn-show-images"
							data-setting-type="showImages"
							data-setting-value="imageOn"
							onClick={this.handleSetSetting}
						>
							{i18n.t('Controls.On')}
						</KeydownEnterButton>
						<KeydownEnterButton
							className="btn-hide-images"
							data-setting-type="showImages"
							data-setting-value="imageOff"
							onClick={this.handleSetSetting}
						>
							{i18n.t('Controls.Off')}
						</KeydownEnterButton>
					</div>
					<div className="view-settings__block" id="text-color">
						<p>{i18n.t('SiteColor')}:</p>
						<KeydownEnterButton
							className="black"
							data-setting-type="colorTheme"
							data-setting-value="blackOnWhite"
							onClick={this.handleSetSetting}
						>
							Ц
						</KeydownEnterButton>
						<KeydownEnterButton
							className="white"
							data-setting-type="colorTheme"
							data-setting-value="whiteOnBlack"
							onClick={this.handleSetSetting}
						>
							Ц
						</KeydownEnterButton>
						<KeydownEnterButton
							className="blue"
							data-setting-type="colorTheme"
							data-setting-value="blueOnBlue"
							onClick={this.handleSetSetting}
						>
							Ц
						</KeydownEnterButton>
						<KeydownEnterButton
							className="sand"
							data-setting-type="colorTheme"
							data-setting-value="sandTheme"
							onClick={this.handleSetSetting}
						>
							Ц
						</KeydownEnterButton>
						<KeydownEnterButton
							className="brown"
							data-setting-type="colorTheme"
							data-setting-value="brownTheme"
							onClick={this.handleSetSetting}
						>
							Ц
						</KeydownEnterButton>
					</div>
					<div className="view-settings__block">
						<p>{i18n.t('Font')}:</p>
						<KeydownEnterButton
							className="btn-font-serif"
							data-setting-type="fontType"
							data-setting-value="sans"
							onClick={this.handleSetSetting}
						>
							{i18n.t('Serif')}
						</KeydownEnterButton>
						<KeydownEnterButton
							className="btn-font-sans"
							data-setting-type="fontType"
							data-setting-value="noSerif"
							onClick={this.handleSetSetting}
						>
							{i18n.t('SansSerif')}
						</KeydownEnterButton>
					</div>
					<div className="view-settings__block">
						<p>{i18n.t('LineHeight')}:</p>
						<KeydownEnterButton
							data-setting-type="lineHeight"
							data-setting-value="line_1_5"
							onClick={this.handleSetSetting}
						>
							1.5
						</KeydownEnterButton>
						<KeydownEnterButton
							data-setting-type="lineHeight"
							data-setting-value="line_2_0"
							onClick={this.handleSetSetting}
						>
							2.0
						</KeydownEnterButton>
						<KeydownEnterButton
							data-setting-type="lineHeight"
							data-setting-value="line_2_5"
							onClick={this.handleSetSetting}
						>
							2.5
						</KeydownEnterButton>
					</div>
					<div className="view-settings__block">
						<p>{i18n.t('LetterSpacing')}:</p>
						<KeydownEnterButton
							data-setting-type="letterSpacing"
							data-setting-value="letter-spacing1"
							onClick={this.handleSetSetting}
						>
							1
						</KeydownEnterButton>
						<KeydownEnterButton
							data-setting-type="letterSpacing"
							data-setting-value="letter-spacing2"
							onClick={this.handleSetSetting}
						>
							2
						</KeydownEnterButton>
						<KeydownEnterButton
							data-setting-type="letterSpacing"
							data-setting-value="letter-spacing4"
							onClick={this.handleSetSetting}
						>
							4
						</KeydownEnterButton>
					</div>
				</div>
				<div className="view-settings__row">
					<KeydownEnterButton onClick={this.handleDefaultSettings}>
						<FaCog /> {i18n.t('DropSettings')}
					</KeydownEnterButton>
					<KeydownEnterButton onClick={this.handleBasicView}>
						<FaEyeSlash /> {i18n.t('RegularVersion')}
					</KeydownEnterButton>
				</div>
			</section>
		);
	}
}
