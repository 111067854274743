import {connect} from 'react-redux';
import {updateSelectedDay} from 'app/actions/AppActions';
import {
	getIsMonitor,
	getSelectedDay,
	isFeedbackEnabled,
	isMobileSelector,
} from 'app/selectors/AppSelectors';
import {getAdvertisementsIsLoaded} from 'app/selectors/AdvertisementsSelectors';
import MenuComponent from 'app/components/MenuComponent';
import MenuCalendarComponent from 'app/components/MenuCalendarComponent';

const mapStateToProps = state => ({
	selectedDay: getSelectedDay(state),
	feedback: isFeedbackEnabled(state),
	isLoadedAdvertisements: getAdvertisementsIsLoaded(state),
	isMobile: isMobileSelector(state),
	isMonitor: getIsMonitor(state)
});

export default {
	Navigation: connect(mapStateToProps, {updateSelectedDay}, null, {pure: false})(MenuComponent),
	Calendar: connect(mapStateToProps, {updateSelectedDay})(MenuCalendarComponent)
};
