import {handleActions} from 'redux-actions';
import * as actions from 'app/constants/AppConstants';

const initialState = {
	afisha: [],
	countries: [],
	formats: [],
	genres: [],
	persons: [],
	calendar: {},
	cinemas: [],
	discounts: [],
	seanceDisplaySettings: {},
	error: {
		code: null
	},
	isAfishaFetching: true,
	isAfishaInfoFetching: true,
	all_qualifiers: []
};

export default handleActions({
	[actions.GET_AFISHA_REQUEST]: state => ({
		...state,
		isAfishaFetching: true,
		error: {
			code: null
		}
	}),

	[actions.GET_AFISHA_SUCCESS]: (state, action) => ({
		...state,
		...action.payload,
		isAfishaFetching: false
	}),

	[actions.GET_AFISHA_INFO_REQUEST]: state => ({
		...state,
		isAfishaInfoFetching: true,
		error: {
			code: null
		}
	}),

	[actions.GET_AFISHA_INFO_SUCCESS]: (state, action) => ({
		...state,
		...action.payload,
		isAfishaInfoFetching: false
	}),

	[actions.SET_SEANCE_DISPLAY_SETTINGS]: (state, action) => ({
		...state,
		...action.payload
	}),

	[actions.SET_AFISHA_ERROR]: (state, {payload: {code}}) => ({
		...state,
		isAfishaInfoFetching: false,
		error: {
			...state.error,
			code
		}
	}),

	[actions.SET_QUALIFIERS]: (state, {payload}) => ({
		...state,
		all_qualifiers: payload
	})

}, initialState);
