import KeydownEnterButton from 'app/components/KeydownEnterButtonComponent';
import { EMAIL_REGEXP } from 'app/constants/refund';
import { EmailResendStatus } from 'app/types/emailResendReducer';
import { Errors } from "app/types/refund";
import { getErrorFields, getTextByNotFound, getTextBySend } from "app/utils/utils";
import { ChangeEvent, SyntheticEvent, memo, useCallback, useEffect, useState } from "react";
import Loader from 'tochkak-ui/lib/Loader';
import LawDocumentsCheckboxContainer from '../documents/LawDocumentsCheckboxContainer';
import Alert from "./Alert";
import Input from "./Input";
import 'css/refund/index.scss';
import {lawDocumentsRefund} from "css/LawDocuments.module.scss";

const INITIAL_STATE = {
	contacts: '',
	order: '',
};

function Refund({ isFetching, resendStatus, findTickets, resendTicket, setEmailResendStatus }) {
	const [form, setForm] = useState(INITIAL_STATE);
	const [actualEmail, setActualEmail] = useState('');
	const [isUserAgreeWithLegalDocs, setIsUserAgreeWithLegalDocs] = useState(true);
	const [errors, setErrors] = useState<Errors>({});
	const [cinemaID, setCinemaID] = useState(Infinity);

	const hasErrors = !_.isEmpty(errors);
	const isSuccess = resendStatus === EmailResendStatus.ResentSuccessfully;
	const hasWarnings = resendStatus === EmailResendStatus.NotFound;
	const areTicketsFound = resendStatus === EmailResendStatus.Found;
	const isDisabledButton = (!form.contacts && !form.order) || isSuccess || !isUserAgreeWithLegalDocs;

	useEffect(() => {
		const script = document.createElement("script");
		script.src = "//code.jivo.ru/widget/8QZbL6zqJY";
		script.async = true;
		document.body.appendChild(script);

		const cinemaID = new URLSearchParams(window.location.search).get("cinemaID");
		setCinemaID(Number(cinemaID) || Infinity);
	}, []);

	useEffect(() => {
		if (!hasErrors && hasWarnings) {
			setEmailResendStatus('');
		}
	}, [form]);

	const handleChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
		const { value, name, checked } = event.target;
		let orderNumber = value;
		const regex = /^[0-9]*$/;

		if (!regex.test(value) && name === 'order') {
			orderNumber = value.replace(/[^0-9]/g, '');
		}

		switch (name) {
			case "legal-docs":
				setIsUserAgreeWithLegalDocs(checked);
				break;
			case "actualEmail":
				setActualEmail(value);
				break;
			case "order":
				setForm({
					...form,
					order: orderNumber,
				});
				break;
			default:
				setForm({
					...form,
					[name]: value,
				})
				break;
		}

		setErrors(_.omit(errors, name));
	}, [form, errors]);

	const handleSubmit = useCallback((event: SyntheticEvent) => {
		event.preventDefault();

		const errors = getErrorFields(form);

		if (!_.isEmpty(errors)) {
			setErrors(errors);
		} else {
			const { order, contacts } = form;
			const isEmail = EMAIL_REGEXP.test(contacts);
			const contact = isEmail || !contacts.startsWith('8') ? contacts : contacts.replace('8', '+7');

			setErrors({});
			findTickets({
				pin_code: order,
				[isEmail ? 'email' : 'phone']: contact,
			});
		}
	}, [form]);

	const handleResendEmail = useCallback((event: SyntheticEvent) => {
		event.preventDefault();

		const errors = getErrorFields({ actualEmail });

		if (!_.isEmpty(errors)) {
			setErrors(errors);
		} else {
			setErrors({});
			resendTicket({
				pin_code: form.order,
				email: actualEmail,
			});
		}
	}, [actualEmail]);

	const renderButtonSubmit = (isNeedResend = false) => (
		<KeydownEnterButton
			className="button button--refund"

			isDisabled={isDisabledButton}
			onClick={isNeedResend ? handleResendEmail : handleSubmit}
		>
			{isFetching ? <Loader size="20px" border="2px" color="rgb(var(--accent-plus)" /> : i18n.t('SendTickets')}
		</KeydownEnterButton>
	);

	return (
		<main className="refund">
			<form action="#" method="post" className="form form--refund">
				<h2 className="form__title">{i18n.t('SendTicketOnceMore')}</h2>
				<Input
					type="text"
					name="contacts"
					label={i18n.t('EmailOrPhone')}
					placeholder={i18n.t('EmailOrPhone')}
					id="contacts"
					isDisabled={isSuccess}
					validators={{ errors, hasWarnings, isSuccess: isSuccess || areTicketsFound }}
					onChange={handleChange}
					value={form.contacts}
					autoFocus
					required
				/>
				<Input
					type="text"
					name="order"
					isDisabled={isSuccess}
					label={i18n.t('OrderNumber')}
					validators={{ errors, hasWarnings, isSuccess: isSuccess || areTicketsFound }}
					onChange={handleChange}
					value={form.order}
					placeholder="00000000"
					maxLength={11}
					id="order"
					required
				/>
				<LawDocumentsCheckboxContainer
					cinemaID={cinemaID}
					isChecked={isUserAgreeWithLegalDocs}
					onChange={handleChange}
					name="legal-docs"
					classes={`${lawDocumentsRefund}`}
				/>
				{areTicketsFound
					? (
						<Alert
							className="form__alert"
							title={i18n.t('TicketsFound')}
							text={i18n.t('EnterEmailForSendingTickets')}
						>
							<>
								<Input
									type="email"
									name="actualEmail"
									placeholder="Email"
									validators={{ errors }}
									onChange={handleChange}
									value={actualEmail}
									autoFocus
									required
								/>
								{renderButtonSubmit(true)}
							</>
						</Alert>
					)
					: renderButtonSubmit()
				}
				{(hasWarnings && !hasErrors) && (
					<Alert
						className="form__alert"
						title={i18n.t('TicketsNotFound')}
						text={getTextByNotFound(form.contacts)}
					/>
				)}
				{isSuccess && (
					<Alert
						className="form__alert"
						iconSrc="./img/status-done.svg"
						title={i18n.t('TicketsAreSent')}
						text={getTextBySend(actualEmail || form.contacts)}
					/>
				)}
			</form>
		</main>
	);
}

export default memo(Refund);
