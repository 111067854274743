import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';

const getDisplayName = (WrappedComponent) =>
	WrappedComponent.displayName || WrappedComponent.name || 'Component';

export default function showComponent(WrappedComponent) {
	class ComponentShower extends PureComponent {

		static displayName = `ComponentShower(${getDisplayName(WrappedComponent)})`;

		static propTypes = {
			show: PropTypes.bool
		};

		static defaultProps = {
			show: true
		};

		render() {
			const {show, ...props} = this.props;

			return (show ? <WrappedComponent {...props} /> : null);
		}
	}

	return ComponentShower;
}
