import {connect} from 'react-redux';
import {
	getCinema,
	isMobileSelector,
	getAddresses,
	getSocials,
	getFilteredPhones,
	getIsSemiblind,
	getIsSeveralCinemasInCity,
	getIsCitySelectShown,
	isFeedbackEnabled,
	getCurrentCityId,
	getIsMonitor,
	getSocialsForMonitor,
	getIsCharlie, getCurrentCityTitle, getCinemaLangsOptions,
} from 'app/selectors/AppSelectors';
import {setSemiblind, setFontSize} from 'app/actions/AppActions';
import FondKinoHeaderComponent from 'app/components/FondKino/FondKinoHeaderComponent';

const mapStateToProps = state => {
	const isMonitor = getIsMonitor(state);
	const isCharlie = getIsCharlie(state);
	const socials = isMonitor || isCharlie ? getSocialsForMonitor(state) : getSocials(state);

	return {
		cinema: getCinema(state),
		cityTitle: getCurrentCityTitle(state),
		phones: getFilteredPhones(state),
		isMobile: isMobileSelector(state),
		socials,
		addresses: getAddresses(state),
		isSemiblind: getIsSemiblind(state),
		isSeveralCinemasInCity: getIsSeveralCinemasInCity(state),
		isCitySelectShown: getIsCitySelectShown(state),
		isFeedbackEnabled: isFeedbackEnabled(state),
		currentCityId: getCurrentCityId(state),
		langOptions: getCinemaLangsOptions(state),
		isMonitor,
		isCharlie
	};
};

export default connect(mapStateToProps, {setSemiblind, setFontSize}, null, {pure: false})(FondKinoHeaderComponent);
