import React from 'react';
import { Route, RouteComponentProps, Switch, withRouter } from 'react-router-dom';
import AfishaContainer from 'app/containers/AfishaContainer';

type Props = RouteComponentProps & {
	addedPath: string,
};

const NotMovieAfishaPageComponent = ({addedPath}: Props) => {
	return (
		<Switch>
			<Route path={`${addedPath}/not_movies`}>
				<AfishaContainer isNotMovie />
			</Route>
		</Switch>
	);
};

export default withRouter(NotMovieAfishaPageComponent);
