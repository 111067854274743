import React, {useState} from 'react';
import classNames from 'classnames';

interface IProps {
	placeholder?: string,
	setFilter: (value: string) => void,
}

const CitySearchInputComponent = ({placeholder = '', setFilter}: IProps) => {
	const [isFocused, setIsFocused] = useState(false);
	const inputClassName = classNames(
		'cities-list-select__city-input',
		'city-search-input',
		{'city-search-input--focused': isFocused},
	);

	const toggleFocus = () => {
		setIsFocused(!isFocused);
	};

	const handleChange = ({target}) => {
		setFilter(target.value);
	};

	return (
		<div className={inputClassName}>
			<div className="city-search-input__icon" />
			<input
				className="city-search-input__input-area"
				placeholder={placeholder}
				onFocus={toggleFocus}
				onBlur={toggleFocus}
				onChange={handleChange}
			/>
		</div>
	);
};

export default CitySearchInputComponent;
