export type Address = {
	main: string,
	mapsLink: string,
	place: string,
};

export type Advertisement = {
	id: string,
	title: string,
	url: string,
	image: {
		desktop: string,
		mobile: string,
	},
	created_date_time: string,
	updated_date_time: string,
};

export type Alert = {
	title: string;
	subtitle: string;
};
export type Apps = {
	apple?: string,
	google?: string,
};

export type Branding = {
	enabled: boolean,
	img_url: string | null,
	bg_color: string | null,
	link: string | null,
	new_tab: boolean,
};

export enum PaymentCard {
	MIR = "mir",
	VISA = "visa",
	MASTERCARD = "mastercard",
	MAESTRO = "maestro",
	GOOGLE_PAY = "googlePay",
	APPLE_PAY = "applePay",
	CLICK = "click",
	PAYME = "payme",
	SBP = "sbp",
	SBERPAY = "sberpay",
	KASPI = "kaspi",
}

export type Cinema = {
	address: string | null,
	app: {
		appstore: string | null,
		googleplay: string | null,
	},
	modules: Modules,
	city: string,
	facebook: string | null,
	id: number,
	instagram: string | null,
	maps: string | null,
	odnoklassniki: string | null,
	langs?: string[],
	payment: {
		active: boolean,
		card: PaymentCard[],
	},
	phoneNumbers?: string[],
	place: string | null,
	selectButton: {
		options: Option[]
		selectedOptionValue: string | null,
	},
	display_settings: {
		seance_display_settings: SeanceDisplaySettings,
		section_display_settings: SectionDisplaySettings,
	},
	soonPageIsEnabled: boolean,
	title: string,
	twitter: string | null,
	vk: string | null,
	workTime: object,
	work_hours: WorkHours,
	youtube: string | null,
	coordinates: string,
	hasCustomMapMarker?: boolean,
	isWidgetDisabled?: boolean,
	isMonitor?: boolean,
	isCharlie?: boolean,
	isArman3D?: boolean,
	isKinokassa?: boolean,
	isProCultureSectionDisabled?: boolean,
	rambler_id: number | null,
	rambler_external: boolean,
	city_id: number,
	time_zone: number,
	alert: Alert,
	payment_methods: {
		gateway_merchant: string,
		apple_pay: {
			is_enabled: boolean,
			merchant?: string,
		},
		google_pay: {
			is_enabled: boolean,
		},
		currency: string,
	},
	saleRules: boolean,
	details: boolean
};

export type CinemaWithDailySeances = {
	id: number,
	title: string,
	address: string,
	seances: IPreparedDailySeance[],
};

export type City = {
	id: number;
	title: string;
	region_id: number;
	region: string;
};

export type ContactData = {
	cinema_id: number,
	phones: Phone[],
	addresses: Option[],
	socials: Array<{ name: string, value: string }>,
	apps: Array<{ name: string, value: string }>,
	is_about_exist: boolean,
	img_url: string | null,
	coordinates: string | null,
	map_provider: string | null,
	cinema_opening_hours: string | null,
	email: string | null,
};

export type Contacts = {
	addresses: Address,
	apps: Apps,
	phones: Phone[],
	socials: Socials,
	cinema_id: number | null,
	is_about_exist: boolean,
	img_url: string | null,
	coordinates: string | null,
	map_provider: string | null,
	cinema_opening_hours: string | null,
	email: string | null,
};

export type CustomPage = {
	title: string,
	href: string,
	id: string,
	sub?: CustomPage[],
};

export type DailyRelease = {
	age_rating: string,
	genres: TitleWithId[],
	id: number,
	rambler_id: number | null,
	title: string,
};

export type DailySeance = {
	cinema_id: number,
	formats: TitleWithId[],
	length: number,
	hall: {
		id: number,
		is_vip: boolean,
		seats_limit: number | null,
		title: string,
	}
	id: string,
	is_allowed_online_sale: boolean,
	price: {
		max: number,
		min: number,
	}
	release_id: number,
	start_date: string,
	start_date_time: string,
	free_places_count: number | null,
};

export type Hall = {
	title: string;
	formats: string[];
	capacity: number;
	is_vip: boolean;
	custom_title: string;
	description: string;
	photos: HallPhoto[];
	is_hidden: boolean;
};

export type HallPhoto = {
	original: string;
	thumbnail: string;
};

export type InfoSectionsLink = {
	id: string;
	href: string;
	title: string;
};

export interface IPreparedDailySeance extends Omit<DailyRelease, 'id'>, DailySeance {
	release_id: number;
}

export type Modules = {
	goods_enabled: boolean;
	bar_enabled: boolean;
	subsale_enabled: boolean;
	in_hall_order_enabled: boolean;
	articles_enabled: boolean;
	branding_enabled: boolean;
	promotions_enabled: boolean;
};

export type News = {
	id: string,
	title: string,
	body: string,
	description: string,
	image?: {
		big: string,
		small: string,
	},
	created_date_time: string,
	updated_date_time: string | null,
};

export type Option = {
	value: string,
	title: string,
};

export type Phone = {
	name: string,
	value: string,
	comment: string,
};

export type SeanceDisplaySettings = {
	buy: boolean,
	format: boolean,
	hall: boolean,
	price: boolean,
	vip_badge: boolean,
};

export type SectionDisplaySettings = {
	imax: boolean,
	vip: boolean,
	pro_culture: boolean,
	not_movie: boolean,
};

export type Socials = {
	facebook: string | null,
	instagram: string | null,
	ok: string | null,
	twitter: string | null,
	vk: string | null,
	youtube: string | null,
	telegram: string | null,
};

export type TitleWithId = {
	id: number,
	title: string,
};

export type WorkHours = {
	open_time: string,
	close_time: string,
	weekend_open: string,
	weekend_close: string,
};

type AnalyticsDataItem = {
	client_id: string;
} | null;

export type AnalyticsData = {
	cinema_id: number;
	google_analytics: AnalyticsDataItem;
	google_tag_manager: AnalyticsDataItem;
	yandex_metrics: AnalyticsDataItem;
};

export interface ICinemaInfo {
	id: number,
	title: string | null,
	address: string | null,
}