import {createSelector} from 'reselect';
import {isMobileSelector} from 'app/selectors/AppSelectors';

export const getAdvertisementsInfo = state => state.advertisementsReducer.advertisements;
export const getAdvertisementsIsLoaded = state => state.advertisementsReducer.isLoaded;

export const getAdvertisementsList = createSelector(
	[getAdvertisementsInfo, isMobileSelector],
	(advertisements, isMobile) => _.map(advertisements, ({id, title, image, url, advertiser_desktop, advertiser_mobile}) => ({
		id,
		title,
		original: isMobile ? image.mobile : image.desktop,
		bulletClass: 'bullets',
		url: isMobile ? url.mobile : url.desktop,
		originalClass: url.mobile || url.desktop ? 'slider__image--with-pointer' : '',
		advertiser: isMobile ? advertiser_mobile : advertiser_desktop
	})),
);
