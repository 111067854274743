import React from 'react';
import {withRouter} from 'react-router-dom';
import PropTypes from 'prop-types';
import KeydownEnterButton from 'app/components/KeydownEnterButtonComponent';
import KeydownEnterLink from 'app/components/KeydownEnterLinkComponent';
import CitySearchInput from 'app/components/CitySelect/CitySearchInputComponent';
import {closeIcon} from 'app/constants/IconsConstants';
import {getBusinessDay} from 'app/selectors/AppSelectors';

const CitiesListSelectComponent = ({
	citiesData,
	hidePopup,
	setCityFilter,
	saveNewCurrentCityId,
	setSelectedDay,
	history: {push, location}
}) => {
	const citySearchInputPlaceholder = citiesData.length > 0 && citiesData[0].title;

	const handleHideButtonClick = () => {
		setCityFilter('');
		hidePopup();
	};

	const handleClick = ({currentTarget}) => {
		const newCityId = Number(currentTarget.dataset.cityId);

		saveNewCurrentCityId(newCityId);
		hidePopup();
		setSelectedDay(getBusinessDay());

		if (location.pathname !== '/') {
			push('/');
		}
	};

	return (
		<div className="cities-list-select">
			<div className="cities-list-select__header">
				<h1>{i18n.t('ChooseYourCity')}</h1>
				<KeydownEnterButton
					className="cities-list-select__hide-btn"
					onClick={handleHideButtonClick}
				>
					{closeIcon}
				</KeydownEnterButton>
			</div>
			<CitySearchInput placeholder={citySearchInputPlaceholder} setFilter={setCityFilter} />
			<div className="cities-list-select__list">
				{citiesData.map(({id, title, region}) => (
					<KeydownEnterLink
						key={id}
						tabIndex={0}
						type="plain"
						data-city-id={id}
						onClick={handleClick}
						className="cities-list-select__city-item"
					>
						<span>{title}</span>
						{region && <span className="cities-list-select__city-item--region">{region}</span>}
					</KeydownEnterLink>
				))}
			</div>
		</div>
	);
};

CitiesListSelectComponent.propTypes = {
	citiesData: PropTypes.arrayOf(PropTypes.shape({
		id: PropTypes.number,
		title: PropTypes.string
	})).isRequired,
	setSelectedDay: PropTypes.func,
	hidePopup: PropTypes.func.isRequired,
	setCityFilter: PropTypes.func.isRequired,
	saveNewCurrentCityId: PropTypes.func.isRequired,
	history: PropTypes.shape({
		push: PropTypes.func.isRequired,
		location: PropTypes.shape({
			pathname: PropTypes.string.isRequired
		}).isRequired
	}).isRequired
};

export default withRouter(CitiesListSelectComponent);
