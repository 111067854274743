import { connect } from 'react-redux';
import { getAfisha, getAfishaInfo } from 'app/actions/AfishaActions';
import { getAfishaSelector, getIsFetching, getProCultureAfisha, getSeanceDates } from 'app/selectors/AfishaSelectors';
import {
	getSelectedDay,
	getCurrentCityId,
	getRoutePrefix,
	getIsKinokassa,
	getCurrentCinemas,
} from 'app/selectors/AppSelectors';
import { getAllReleasesSoon } from 'app/selectors/ReleasesSoonSelectors';
import { getPromosWithCinemas } from 'app/selectors/PromoSelectors';
import { getNewsSelector } from 'app/selectors/NewsSelectors';
import getReleasesSoon from 'app/actions/ReleasesSoonActions';
import AfishaComponent from 'app/components/AfishaComponent';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { setSelectedDay } from 'app/actions/AppActions';

const mapStateToProps = (state, { isProCulture }: Props) => ({
	afisha: isProCulture ? getProCultureAfisha(state) : getAfishaSelector(state),
	hasReleasesSoon: !!getAllReleasesSoon(state).length,
	isFetching: getIsFetching(state),
	seanceDates: getSeanceDates(state),
	selectedDay: getSelectedDay(state),
	cityId: getCurrentCityId(state),
	promos: getPromosWithCinemas(state),
	news: getNewsSelector(state),
	prefix: getRoutePrefix(state),
	isKinokassa: getIsKinokassa(state),
	cinemasCount: getCurrentCinemas(state).length,
});

const mapDispatchToProps = {
	getAfisha,
	getAfishaInfo,
	getReleasesSoon,
	setSelectedDay,
};

type Props = {
	isVip?: boolean,
	isImax?: boolean,
	isProCulture?: boolean,
	isNotMovie?: boolean,
	isPlanetarium?: boolean,
};

export type AfishaProps = ReturnType<typeof mapStateToProps> & typeof mapDispatchToProps & Props & RouteComponentProps;

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(AfishaComponent));
