import * as actions from 'app/constants/AppConstants';
import { KRASNODAR_CITY_ID } from 'app/constants/CinemaConstants';
import { KINOKASSA_API_URL } from 'app/constants/SettingsConstants';
import httpRequestPromise from 'app/httpRequestPromise';
import {normalizeResponseData} from 'app/selectors/Helpers';
import {
	getAllCinemas,
	getAllContacts,
	getCurrentCityId,
	getCurrentCityTitle,
	getIsMonitor,
} from 'app/selectors/AppSelectors';
import get from 'lodash/get';
import intersectionWith from 'lodash/intersectionWith';
import { createAction } from 'redux-actions';

export const showPopup = createAction(
	actions.SHOW_POPUP,
	popupOptions => ({popupOptions}),
);
export const hidePopup = createAction(actions.HIDE_POPUP);

const getAppDataRequest = createAction(
	actions.GET_APP_DATA_REQUEST,
);

const getAppDataSuccess = createAction(
	actions.GET_APP_DATA_SUCCESS,
	data => (data),
);

export const setAppError = createAction(
	actions.SET_APP_ERROR,
	(code) => ({code}),
);

export const setSelectedDay = createAction(
	actions.SET_SELECTED_DAY,
	selectedDay => ({selectedDay}),
);

const setContacts = createAction(
	actions.SET_CONTACTS,
	contacts => ({contacts}),
);

export const resetCurrentCinemaInfo = createAction(
	actions.RESET_CURRENT_CINEMA_INFO,
);

const setAboutCinema = createAction(
	actions.SET_ABOUT_CINEMA,
	aboutCinema => ({aboutCinema}),
);

const setSeveralCinemasAboutData = createAction(
	actions.SET_SEVERAL_CINEMAS_ABOUT_DATA,
	severalCinemasAboutData => ({severalCinemasAboutData}),
);

const setHalls = createAction(
	actions.SET_HALLS,
	halls => ({halls}),
);

export const setIsMobile = createAction(
	actions.SET_IS_MOBILE,
	(isMobile) => (isMobile),
);

export const setSemiblind = createAction(
	actions.SET_SEMIBLIND,
	(isSemiblind) => (isSemiblind),
);

export const setFontSize = createAction(
	actions.SET_FONT_SIZE,
	(fontSize) => (fontSize),
);

export const setNewCurrentCinemaData = createAction(
	actions.SET_NEW_CURRENT_CINEMA_DATA,
	(cinema, alert) => ({cinema, alert}),
);

export const setCityFilter = createAction(
	actions.SET_CITY_FILTER,
	(cityFilter) => ({cityFilter}),
);

export const setCurrentCityId = createAction(
	actions.SET_CURRENT_CITY_ID,
	(newId) => ({newId}),
);

export const setIsUserAuth = createAction(
	actions.SET_IS_USER_AUTH,
	isUserAuth => ({isUserAuth}),
);

export const setIsWidgetInKioskMode = createAction(
	actions.SET_IS_WIDGET_IN_KIOSK_MODE,
	isWidgetInKioskMode => ({isWidgetInKioskMode}),
);

export const setCountUnreadNotifications = createAction(
	actions.SET_COUNT_UNREAD_NOTIFICATIONS,
	countUnreadNotifications => ({countUnreadNotifications}),
);

export const updateCurrentCinema = (newCinemaId, cityId) => (dispatch, getState) => {
	const state = getState();
	const allCinemas = getAllCinemas(state);
	const allContacts = getAllContacts(state);
	const currentCityTitle = getCurrentCityTitle(state);
	const cinemaID = newCinemaId || allCinemas.filter(cinema => cinema.city_id === cityId)[0].id;

	const newCurrentCinema = _.findWhere(allCinemas, {id: cinemaID}) || {};
	const newCurrentCinemaAlertMessage = newCurrentCinema.alert;
	const newCurrentCinemaContacts = _.chain(allContacts)
		.findWhere({cinema_id: cinemaID})
		.mapObject((value, key) => (
			key === 'phones' || !_.isObject(value) ? value : normalizeResponseData(value)
		)).value();

	dispatch(setNewCurrentCinemaData({...newCurrentCinema, city: currentCityTitle}, newCurrentCinemaAlertMessage));
	dispatch(resetCurrentCinemaInfo());
	dispatch(setContacts(newCurrentCinemaContacts));
};

export const saveNewCurrentCityId = (cityId) => (dispatch) => {
	localStorage.setItem('currentCityId', cityId);

	dispatch(setCurrentCityId(cityId));
	dispatch(setCityFilter(''));
};

export const getAppData = (cinemaId) => async (dispatch) => {
	dispatch(getAppDataRequest());

	try {
		let response = await httpRequestPromise(`${KINOKASSA_API_URL}/api/v2/app/${cinemaId}`, 'GET');
		const {cinemas, cities, branding, modules} = response;
		const cinema = cinemas.length
			? {
				..._.omit(cinemas[0], 'alert'),
				city: _.findWhere(cities, {id: cinemas[0].city_id}).title
			}
			: {};
		dispatch(getAppDataSuccess({
			cinema,
			modules,
			alert: cinemas.length ? cinemas[0].alert : null,
			branding,
			allCinemas: cinemas,
			allCities: cities
		}));
	} catch (error) {
		dispatch(setAppError(actions.INTERNAL_SERVICE_ERROR));
	}
};

export const getContactsRequest = (currentCinemaID) => async (dispatch) => {
	try {
		const response = await httpRequestPromise(`${KINOKASSA_API_URL}/api/v2/cinema/contacts`, 'GET');
		const currentCinemaContacts = _.find(response, ({cinema_id: cinemaID}) => cinemaID === currentCinemaID);
		const data = _.mapObject(currentCinemaContacts, (value, key) =>
			key === 'phones' || !_.isObject(value) ? value : normalizeResponseData(value));

		dispatch(setContacts(data));
		// eslint-disable-next-line no-empty
	} catch (error) { }
};

export const getAboutCinemaRequest = (currentCinemaID) => async (dispatch) => {
	try {
		const response = await httpRequestPromise(`${KINOKASSA_API_URL}/api/v2/cinema/about`, 'GET');
		const {about} = _.find(response, ({cinema_id: cinemaID}) => cinemaID === currentCinemaID) || {};

		dispatch(setAboutCinema(about));
		// eslint-disable-next-line no-empty
	} catch (error) { }
};

export const updateSelectedDay = day => dispatch => {
	dispatch(setSelectedDay(day));
};

export const changePageTitle = title => {
	const separate = ' — ';
	const currentTitleArray = document.title.split(separate);
	const currentTitle = currentTitleArray[currentTitleArray.length - 1];

	document.title = title
		? title + separate + currentTitle
		: currentTitle;
};

export const closeSmartBanner = createAction(
	actions.CLOSE_SMART_BANNER,
);

export const getFondKinoAppData = () => async (dispatch, getState) => {
	dispatch(getAppDataRequest());

	try {
		const response = await httpRequestPromise(
			`${KINOKASSA_API_URL}/api/v2/app?with_cities=true&with_regions=true&with_contacts=true`, 'GET',
		);

		const {
			cinemas,
			cities,
			regions,
			contacts,
			supposed_city: supposedCityId,
			branding,
			modules
		} = response;

		const state = getState();
		const isMonitor = getIsMonitor(state);
		const getSupposedCityId = () => isMonitor ? KRASNODAR_CITY_ID : (supposedCityId || get(cities[0], 'id'));
		const currentCityId = getCurrentCityId(state)
			|| getSupposedCityId()
			|| get(cities[0], 'id');
		const isCurrentCityInCities = !!cities.map(({id}) => id).find(id => id === currentCityId);
		const currentCity = _.findWhere(cities, {id: isCurrentCityInCities ? currentCityId : getSupposedCityId()}) || {};
		const currentCinema = _.findWhere(cinemas, {
			city_id: isCurrentCityInCities ? currentCityId : getSupposedCityId()
		}) || {};

		const citiesWithRegions = cities.map(city => {
			const region = _.findWhere(regions, {id: city.region_id}) || {};

			return {
				...city,
				region: region.title
			};
		});

		dispatch(getAppDataSuccess({
			cinema: {
				..._.omit(currentCinema, 'alert'),
				city: currentCity.title
			},
			allCities: citiesWithRegions,
			allCinemas: cinemas,
			allContacts: contacts,
			alert: currentCinema.alert,
			modules,
			branding
		}));
		dispatch(saveNewCurrentCityId(currentCity.id));
	} catch (error) {
		dispatch(setAppError(actions.INTERNAL_SERVICE_ERROR));
	}
};

export const getSeveralCinemasAboutRequest = (currentCinemasIds) => async (dispatch) => {
	try {
		const response = await httpRequestPromise(`${KINOKASSA_API_URL}/api/v2/cinema/about`, 'GET');
		const aboutData = intersectionWith(
			response,
			currentCinemasIds,
			(aboutItem, cinemaId) => aboutItem.cinema_id === cinemaId,
		);

		dispatch(setSeveralCinemasAboutData(aboutData));
	} catch (error) {
		dispatch(setAppError(actions.INTERNAL_SERVICE_ERROR));
	}
};

export const getHallsRequest = (cinemaId) => async (dispatch) => {
	try {
		const halls = await httpRequestPromise(`${KINOKASSA_API_URL}/api/v2/cinema/${cinemaId}/hall`, 'GET');

		dispatch(setHalls(halls));
	} catch (error) {
		dispatch(setAppError(actions.INTERNAL_SERVICE_ERROR));
	}
};
