import {connect} from 'react-redux';
import {getApps, getCinema, isKinokassaApp} from 'app/selectors/AppSelectors';
import SmartBannerComponent from 'app/components/SmartBannerComponent';
import {closeSmartBanner} from 'app/actions/AppActions';

const mapStateToProps = state => ({
	apps: getApps(state),
	cinema: getCinema(state),
	isKinokassaApp: isKinokassaApp(state)
});

export default connect(mapStateToProps, {closeSmartBanner})(SmartBannerComponent);
