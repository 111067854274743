import {connect} from 'react-redux';
import {
	getCinema,
	hasSelectButton,
	isMobileSelector,
	getAddresses,
	getSocials,
	getContactsPhones,
	getIsSemiblind,
	getIsCitySelectShown,
	getMenuLinks,
	getIsSeveralCinemasInCity,
	isFeedbackEnabled as getIsFeedbackEnabled,
	getCinemaLangsOptions,
} from 'app/selectors/AppSelectors';
import {setSemiblind, setFontSize} from 'app/actions/AppActions';
import HeaderComponent from 'app/components/HeaderComponent';

const mapStateToProps = state => ({
	cinema: getCinema(state),
	menulinks: getMenuLinks(state),
	phones: getContactsPhones(state),
	hasSelectButton: hasSelectButton(state),
	isMobile: isMobileSelector(state),
	langOptions: getCinemaLangsOptions(state),
	socials: getSocials(state),
	addresses: getAddresses(state),
	isSemiblind: getIsSemiblind(state),
	isSeveralCinemasInCity: getIsSeveralCinemasInCity(state),
	isCitySelectShown: getIsCitySelectShown(state),
	isFeedbackEnabled: getIsFeedbackEnabled(state)
});

export default connect(mapStateToProps, {setSemiblind, setFontSize}, null, {pure: false})(HeaderComponent);
