import React from 'react';
import {convertToRubles, getTitles} from 'app/selectors/Helpers';
import WidgetOverlay from 'app/containers/WidgetOverlayContainer';
import cn from 'classnames';
import { DailySeance } from 'app/types/common';

interface IProps {
	seance: DailySeance,
	releaseId: number,
	ramblerReleaseId: number | null,
	seanceDisplaySettings: {
		format: boolean,
		hall: boolean,
		price: boolean,
		vip_badge: boolean,
	},
	page: string,
	currencySymbol: string,
	isMonitor: boolean,
}

const SeanceItemComponent = ({
	seance: {
		start_date_time: startDateTime,
		price: {
			min: minPrice,
			max: maxPrice,
		},
		id: seanceId,
		formats,
		hall,
		cinema_id: cinemaId,
		free_places_count,
	},
	seanceDisplaySettings: {
		format: isSettingsFormat,
		hall: isSettingsHall,
		price: isSettingsPrice,
		vip_badge: isSettingsVip,
	},
	releaseId,
	ramblerReleaseId,
	page,
	currencySymbol,
	isMonitor,
}: IProps) => {
	const hasDescription = isSettingsFormat || isSettingsPrice;
	const hasAllItemsDescription = isSettingsFormat && isSettingsPrice;
	const seanceMinPrice = convertToRubles(minPrice);
	const seanceMaxPrice = convertToRubles(maxPrice);
	const hasMaxPrice = seanceMaxPrice > seanceMinPrice;
	const isShowVip = hall.is_vip && isSettingsVip;
	const startTime = moment.parseZone(startDateTime).format('HH:mm');
	// TODO: исправить после НГ
	const isSoldOut = [1803].some(item => item === cinemaId)
		? free_places_count !== null && free_places_count === 0
		: false;

	const renderFormats = (seanceFormats) => {
		const formatTitles = getTitles(seanceFormats);
		if (formatTitles.includes('IMAX 3D')) {
			return <img src={'/img/imax3d.svg'} alt={'IMAX 3D'} />;
		} else if (formatTitles.includes('IMAX')) {
			return <img src={'/img/imax.svg'} alt={'IMAX'} />;
		}
		return formatTitles;
	};

	return (
		<div className="seance-item">
			<WidgetOverlay
				cinemaId={cinemaId}
				releaseId={releaseId}
				seanceId={seanceId}
				startTime={startDateTime}
				ramblerReleaseId={ramblerReleaseId}
				isSoldOut={isSoldOut}
				page={page}
			/>
			<div
				className={cn('seance-item__container', {'seance-item__container--disabled': isSoldOut})}
				onClick={(event) => isSoldOut ? event.preventDefault() : _.noop}
			>
				{isSoldOut ? <span className="seance-tooltip" data-tooltip={i18n.t('NoAvailableAeats')}>
					{i18n.t('NoAvailableAeats')}
				</span> : null}
				<div className="seance-item__item">
					<div className="seance-item__time">
						{startTime}
					</div>
					<div className="seance-item__wrapper">
						{hasDescription &&
							<div
								className={
									`seance-item__description ${isShowVip
										? 'seance-item__description--with-vip'
										: ''}`
								}
							>
								{isSettingsFormat && <span>{isMonitor ? renderFormats(formats) : getTitles(formats)}</span>}
								{hasAllItemsDescription && <span className="seance-item--space" />}
								{isSettingsPrice &&
									<span>
										{hasMaxPrice
											? `${i18n.t('of')} ${seanceMinPrice}`
											: seanceMinPrice}&thinsp;{currencySymbol}
									</span>
								}
							</div>
						}
					</div>
				</div>
				{isShowVip &&
					<div className="seance-item__vip" />
				}
			</div>
			{isSettingsHall &&
				<div className="seance-item__hall">{`${i18n.t('Hall')} ${hall.title}`}</div>
			}
		</div>
	);
};

export default SeanceItemComponent;
