import React from 'react';
import KeydownEnterButton from 'app/components/KeydownEnterButtonComponent';
import WidgetOverlay from 'app/containers/WidgetOverlayContainer';
import Counter from 'app/containers/NotificationsCounterContainer';

interface IProps {
	cinemaID: number;
	isUserAuth: boolean;
	isAccountEnabled: boolean;
	isWidgetInKioskMode: boolean;
}

function LoginButton({ cinemaID, isUserAuth, isAccountEnabled, isWidgetInKioskMode }: IProps) {
	if (!isAccountEnabled || isWidgetInKioskMode) {
		return null;
	}

	return (
		<div className="login-btn-wrapper">
			<WidgetOverlay
				cinemaId={cinemaID}
				page={isUserAuth ? 'profile-button' : 'login-button'}
				isSoldOut={false}
				doNotSendAnalytics
			/>
			{!isUserAuth
				? (
					<KeydownEnterButton className="button--accent auth-btn" onClick={_.noop}>
						{i18n.t('Login')}
					</KeydownEnterButton>
				)
				: (
					<KeydownEnterButton className="login-btn" onClick={_.noop}>
						<img src="/img/user.svg" alt="Профиль" width={25} height={25} />
						<Counter />
					</KeydownEnterButton>
				)}
		</div>
	);
}

export default LoginButton;
