import {connect} from 'react-redux';
import {hidePopup} from 'app/actions/AppActions';
import {getPopupOptions} from 'app/selectors/AppSelectors';
import PopupComponent from 'app/components/PopupComponent';
import ShowComponent from 'app/utils/ShowComponent';

const mapStateToProps = state => {
	const popupOptions = getPopupOptions(state);

	return {
		...popupOptions,
		show: !!popupOptions.popupContentNode
	};
};

export default connect(mapStateToProps, {hidePopup})(ShowComponent(PopupComponent));
