import { lazy } from "react"
import {
	ARMAN3D_CINEMA_IDS, ARMAN3D_MART_TARAZ_CINEMA_ID, ATLAS_CINEMA_ID, CHAPLIN_ADK_CINEMA_ID,
	CHAPLIN_KHAN_SHATYR_CINEMA_ID, CHAPLIN_MEGA_ALMA_ATA_CINEMA_ID, CHAPLIN_MEGA_PARK_CINEMA_ID,
	CHAPLIN_MEGA_SILK_WAY_CINEMA_ID, LUMIERE_ID, MAGIC_CINEMA_ID, MAXICINEMA_ID, MONITOR_CINEMA_IDS, PRIME_CINEMA_ID, REDFILM_CINEMA_IDS, ZODIAC_CINEMA_ID
} from "app/constants/CinemaConstants";
import { loadComponent } from 'app/selectors/Helpers';

const PublicContractZodiacCinema = loadComponent(lazy(() => import("./cinemas/zodiacCinema/PublicContractZodiacCinema")));
const PrivacyPolicyZodiacCinema = loadComponent(lazy(() => import("./cinemas/zodiacCinema/PrivacyPolicyZodiacCinema")));
const PublicContract = loadComponent(lazy(() => import("./cinemas/maxiCinema/PublicContract")));
const PrivacyPolicyMaxiCinema = loadComponent(lazy(() => import("./cinemas/maxiCinema/PrivacyPolicyMaxiCinema")));
const PersonalData = loadComponent(lazy(() => import("./cinemas/maxiCinema/PersonalData")));
const DetailsComponent = loadComponent(lazy(() => import("./default/DetailsComponent")));
const SaleRulesComponent = loadComponent(lazy(() => import("./default/SaleRulesComponent")));
const PrivacyPolicyComponent = loadComponent(lazy(() => import("./default/PrivacyPolicyComponent")));
const PublicContractChaplinFirst = loadComponent(lazy(() => import("./cinemas/chaplin/PublicContractChaplinFirst")));
const PublicContractChaplinSecond = loadComponent(lazy(() => import("./cinemas/chaplin/PublicContractChaplinSecond")));
const PublicContractChaplinThird = loadComponent(lazy(() => import("./cinemas/chaplin/PublicContractChaplinThird")));
const PrivacyPolicyArman3d = loadComponent(lazy(() => import("./cinemas/arman3d/PrivacyPolicyArman3d")));
const PublicContractArman3d = loadComponent(lazy(() => import("./cinemas/arman3d/PublicContractArman3d")));
const PublicContractArman3dMartTaraz = loadComponent(lazy(() => import("./cinemas/arman3d/martTarazCinema/PublicContractArman3dMartTaraz")));
const PrivacyPolicyMonitor = loadComponent(lazy(() => import("./cinemas/monitor/PrivacyPolicyMonitor")));
const MagicCinemaSaleRules = loadComponent(lazy(() => import("./cinemas/magicCinema/MagicCinemaSaleRules")));
const PublicContractPrimeCinema = loadComponent(lazy(() => import("./cinemas/primeCinema/PublicContractPrimeCinema")));
const PrivacyPolicyPrimeCinema = loadComponent(lazy(() => import("./cinemas/primeCinema/PrivacyPolicyPrimeCinema")));
const DetailsComponentPrimeCinema = loadComponent(lazy(() => import("./cinemas/primeCinema/DetailsComponentPrimeCinema")));
const PrivacyPolicyAtlasCinema = loadComponent(lazy(() => import("./cinemas/atlasCinema/PrivacyPolicyAtlasCinema")));
const PublicContractAtlasCinema = loadComponent(lazy(() => import("./cinemas/atlasCinema/PublicContractAtlasCinema")));

export interface ILawDocumentLink {
	docLinkTitle?: string;
	docLinkCustomRenderFunction?: (text: string) => React.ReactNode;
	menuControlTitle?: string;
	Component?: React.ReactNode;
}

export const getCinemaDocumentsLinks = (cinemaID: number) => {
	switch (true) {
		case cinemaID === MAGIC_CINEMA_ID:
			return [
				{
					docLinkTitle: i18n.t("YouAgreeTo.purchaseTerms"),
					menuControlTitle: i18n.t("TermsOfPurchase"),
					Component: <MagicCinemaSaleRules />
				},
				{
					menuControlTitle: i18n.t("Requisites"),
					Component: <DetailsComponent />
				},
				{
					docLinkTitle: i18n.t("YouAgreeTo.privacyPolicy"),
					menuControlTitle: i18n.t("PrivacyPolicy"),
					Component: <PrivacyPolicyComponent />
				}
			];
		case cinemaID === ZODIAC_CINEMA_ID:
			return [
				{
					docLinkTitle: i18n.t("YouAgreeTo.privacyPolicy"),
					menuControlTitle: i18n.t("PrivacyPolicy"),
					Component: <PrivacyPolicyZodiacCinema />
				},
				{
					docLinkTitle: i18n.t("YouAgreeTo.offer"),
					menuControlTitle: i18n.t("PublicContract"),
					Component: <PublicContractZodiacCinema />,
				}
			]
		case [MAXICINEMA_ID, LUMIERE_ID].includes(cinemaID):
			return [
				{
					docLinkTitle: i18n.t("YouAgreeTo.protectionPersonalData"),
					menuControlTitle:  i18n.t("PersonalDataProtection"),
					Component: <PersonalData cinemaID={cinemaID}/>
				},
				{
					docLinkTitle: i18n.t("YouAgreeTo.privacyPolicy"),
					menuControlTitle: i18n.t("PrivacyPolicy"),
					Component: <PrivacyPolicyMaxiCinema cinemaID={cinemaID}/>
				},
				{
					docLinkTitle: i18n.t("YouAgreeTo.offer"),
					menuControlTitle: i18n.t("PublicContract"),
					Component: <PublicContract cinemaID={cinemaID}/>
				}
			];
		case cinemaID === ARMAN3D_MART_TARAZ_CINEMA_ID:
				return [
					{
						docLinkTitle: i18n.t("YouAgreeTo.privacyPolicy"),
						menuControlTitle:  i18n.t("PrivacyPolicy"),
						Component: <PrivacyPolicyArman3d isMartTaraz />
					},
					{
						docLinkTitle: i18n.t("YouAgreeTo.offer"),
						menuControlTitle: i18n.t("PublicContract"),
						Component: <PublicContractArman3dMartTaraz />
					}
				];
		case ARMAN3D_CINEMA_IDS.filter((id) => id !== ARMAN3D_MART_TARAZ_CINEMA_ID).includes(cinemaID):
			return [
				{
					docLinkTitle: i18n.t("YouAgreeTo.privacyPolicy"),
					menuControlTitle: i18n.t("PrivacyPolicy"),
					Component: <PrivacyPolicyArman3d />
				},
				{
					docLinkTitle: i18n.t("YouAgreeTo.offer"),
					menuControlTitle: i18n.t("PublicContract"),
					Component: <PublicContractArman3d />
				}
			];
		case cinemaID === CHAPLIN_MEGA_PARK_CINEMA_ID:
			return [
				{
					docLinkTitle: i18n.t("YouAgreeTo.offer"),
					menuControlTitle: i18n.t("PublicContract"),
					Component: <PublicContractChaplinFirst />
				}
			];
		case [CHAPLIN_ADK_CINEMA_ID, CHAPLIN_MEGA_ALMA_ATA_CINEMA_ID].includes(cinemaID):
			return [
				{
					docLinkTitle: i18n.t("YouAgreeTo.offer"),
					menuControlTitle: i18n.t("PublicContract"),
					Component: <PublicContractChaplinSecond />
				}
			];
		case [CHAPLIN_KHAN_SHATYR_CINEMA_ID, CHAPLIN_MEGA_SILK_WAY_CINEMA_ID].includes(cinemaID):
			return [
			{
				docLinkTitle: i18n.t("YouAgreeTo.offer"),
				menuControlTitle: i18n.t("PublicContract"),
				Component: <PublicContractChaplinThird />
			}
		];
		case REDFILM_CINEMA_IDS.includes(cinemaID): {
			const redfilmLinkRenderFunction = (text) => (
				<a
					href="/files/public_offer_redfilm.docx"
					className="law-documents__link"
				>
					{text}
				</a>
			);

			return [
				{
					docLinkTitle: `${i18n.t("YouAgreeTo.purchaseTerms")} ${i18n.t("and")} ${i18n.t("YouAgreeTo.privacyPolicy")}` ,
					docLinkCustomRenderFunction: redfilmLinkRenderFunction,
				}
			]
		}
		case MONITOR_CINEMA_IDS.includes(cinemaID): {
			return [
				{
					docLinkTitle: i18n.t("YouAgreeTo.purchaseTerms"),
					menuControlTitle: i18n.t("TermsOfPurchase"),
					Component: <SaleRulesComponent isMonitor />
				},
				{
					menuControlTitle: i18n.t("Requisites"),
					Component: <DetailsComponent isMonitor />
				},
				{
					docLinkTitle: i18n.t("YouAgreeTo.privacyPolicy"),
					menuControlTitle: i18n.t("PrivacyPolicy"),
					Component: <PrivacyPolicyMonitor />
				}
			]
		}
		case cinemaID === PRIME_CINEMA_ID: {
			return [
				{
					docLinkTitle: i18n.t("YouAgreeTo.privacyPolicy"),
					menuControlTitle: i18n.t("PrivacyPolicy"),
					Component: <PrivacyPolicyPrimeCinema />
				},
				{
					menuControlTitle: i18n.t("Requisites"),
					Component: <DetailsComponentPrimeCinema />
				},
				{
					docLinkTitle: i18n.t("YouAgreeTo.offer"),
					menuControlTitle: i18n.t("PublicContract"),
					Component: <PublicContractPrimeCinema />,
				}
			]
		}
		case cinemaID === ATLAS_CINEMA_ID: {
			return [
				{
					docLinkTitle: i18n.t("YouAgreeTo.privacyPolicy"),
					menuControlTitle: i18n.t("PrivacyPolicy"),
					Component: <PrivacyPolicyAtlasCinema />
				},
				{
					docLinkTitle: i18n.t("YouAgreeTo.offer"),
					menuControlTitle: i18n.t("PublicContract"),
					Component: <PublicContractAtlasCinema />,
				}
			]
		}
		default:
			return [
				{
					docLinkTitle: i18n.t("YouAgreeTo.purchaseTerms"),
					menuControlTitle: i18n.t("TermsOfPurchase"),
					Component: <SaleRulesComponent />
				},
				{
					menuControlTitle: i18n.t("Requisites"),
					Component: <DetailsComponent />
				},
				{
					docLinkTitle: i18n.t("YouAgreeTo.privacyPolicy"),
					menuControlTitle: i18n.t("PrivacyPolicy"),
					Component: <PrivacyPolicyComponent />
				}
			];
	}
}