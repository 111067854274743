import React from 'react';
import KeydownEnterLink from 'app/components/KeydownEnterLinkComponent';
import KeydownEnterButton from 'app/components/KeydownEnterButtonComponent';
import SocialLinksComponent from 'app/components/SocialLinksComponent';
import { parsePhone, setYaReachGoal } from 'app/selectors/Helpers';
import {
	KINOGRADVIDNOE_CINEMA_ID,
	AVRORA_CINEMA_ID,
} from 'app/constants/CinemaConstants';
import { isFondKino } from 'app/selectors/AppSelectors';
import { Phone, Socials } from 'app/types/common';
import { FooterProps } from 'app/containers/FooterContainer';
import WorkTime from 'app/components/WorkTime';

export default class FooterComponent extends React.Component<FooterProps> {
	handleClickItemMenu = (event) => {
		const {isCustomCinemaLink, linkTitle} = event.target.dataset;

		if (isCustomCinemaLink) {
			setYaReachGoal('open-section', {section: linkTitle});
		}

		document.body.scrollTop = document.documentElement.scrollTop = 0;
	};

	handleSemiblindClick = () => {
		const {setSemiblind, setFontSize} = this.props;

		localStorage.setItem('colorTheme', 'blackOnWhite');
		localStorage.setItem('showImages', 'imageOn');
		localStorage.setItem('fontSize', '16');
		localStorage.setItem('fontType', 'noSerif');
		localStorage.setItem('lineHeight', 'line_1_5');
		localStorage.setItem('letterSpacing', 'letter-spacing1');

		setSemiblind(true);
		setFontSize(16);
	};

	renderCityTitle = () => {
		const {currentCity, cinema: {city}} = this.props;

		return (
			<h2 className="footer__column-title footer__column-title--address">
				<span className="footer__city-title">{currentCity || city}</span>
			</h2>
		);
	}

	renderMenuLinks = () => this.props.links.map(({href, title, isCustomCinemaLink, sub }, index) => (
		<KeydownEnterLink
			to={href}
			key={index}
			className="footer__links-item"
			data-is-custom-cinema-link={isCustomCinemaLink}
			data-link-title={sub && sub.length ? sub[0].title : title}
			onClick={this.handleClickItemMenu}
		>
			{title}
		</KeydownEnterLink>
	))

	renderPaymentInfoSection = () => {
		const {
			cinema: {payment: {card: paymentCards}},
			prefix,
		} = this.props;

		const isEnabledSalesRules = !!window.kinosite.additionalModules?.saleRulesPage
		const isEnabledDetails = !!window.kinosite.additionalModules?.detailsPage

		return (
			<React.Fragment>
				<h2 className="footer__column-title">{i18n.t('PaymentMethods')}</h2>
				<div className="footer__payment-cards-wrapper">
					{paymentCards.map((card, index) => (
						<img
							key={index}
							alt={`${card}`}
							className="footer__badge footer__badge--payment"
							src={`/img/badge/card/${card}.svg`}
						/>
					))}
				</div>
				{(isEnabledSalesRules || isEnabledDetails) && (
					<div className="footer__sales-rules-links-wrapper">
						{i18n.t('YouMightKnowWith')}
						{' '}
						{
							isEnabledSalesRules && (
								<KeydownEnterLink to={`${prefix}/sale-rules`} className="footer__links-item">
									{i18n.t('paymentConditions')}
								</KeydownEnterLink>
							)
						}
						{isEnabledDetails && isEnabledSalesRules && ` ${i18n.t('and')} `}
						{
							isEnabledDetails && (
								<KeydownEnterLink to={`${prefix}/details`} className="footer__links-item">
									{i18n.t('requisites_many')}
								</KeydownEnterLink>
							)
						}
					</div>
				)}
			</React.Fragment>
		);
	}

	renderSocialLinks = (socials: Socials) => (
		<div className="footer__socials-wrapper">
			<h2 className="footer__column-title">{i18n.t('SocialNetwork')}</h2>
			<SocialLinksComponent
				className="footer__social"
				linksData={socials}
				isColorIcons
				showTitles={false}
			/>
		</div>
	);

	renderPhoneNumbers = (phones: string[], isArman3D: boolean) => (
		<div className="footer__phone-numbers">
			<h3 className="footer__column-title">{i18n.t('Contacts')}</h3>
			{phones.map((phone, index) => (
				<KeydownEnterLink
					type="plain"
					className="footer__links-item"
					href={`${!isArman3D ? 'tel:' : 'https://wa.me/'}${parsePhone(phone)}`}
					key={index}
					onClick={() => setYaReachGoal('phone-click')}
				>
					{phone}
				</KeydownEnterLink>
			))}
		</div>
	)

	renderPhoneNumbersWithInfo = (phones: Phone[], isArman3D: boolean) => (
		<div className="footer__phone-numbers">
			<h3 className="footer__column-title">{i18n.t('Contacts')}</h3>
			{phones.map((phone, index) => phone.value ? (
				<div className="footer__contact-item" key={index}>
					<KeydownEnterLink
						type="plain"
						className="footer__links-item"
						href={`${!isArman3D ? 'tel:' : 'https://wa.me/'}${parsePhone(phone.value)}`}
						onClick={() => setYaReachGoal('phone-click')}
					>
						{phone.value}
					</KeydownEnterLink>
					{phone.comment && <span className="footer__contact-comment">{phone.comment}</span>}
				</div>
			) : null)}
		</div>
	)

	renderSeveralCinemasLinks = () => (
		<div className="footer__several-cinemas-links-wrapper">
			{this.props.severalCinemasFooterLinks.map(({id, title, address}) => {
				return (
					<div key={id} className="footer__address-title">
						<KeydownEnterLink
							className="footer__subtitle footer__links-item"
							to={`${this.props.prefix}/cinemas/${id}/about`}
						>
							{title}
						</KeydownEnterLink>
						<span>{address}</span>
					</div>
				);
			})}
		</div>
	)

	renderCinemaAddress = () => {
		const {
			cinema: {address},
			addresses: {maps},
		} = this.props;

		return (
			<div className="footer__address-title">
				{!_.isEmpty(maps) ? (
					<KeydownEnterLink
						type="plain"
						className="footer__links-item"
						href={maps}
						target="_blank"
						rel="noopener noreferrer"
					>
						{address}
					</KeydownEnterLink>
				) : (
					<span>{address}</span>
				)}
			</div>
		);
	}

	renderAppsLinks = () => {
		const {apps: {appstore, googleplay}} = this.props;
		const appsLinksData = [
			{
				linkId: 'appstore',
				href: appstore,
			},
			{
				linkId: 'googleplay',
				href: googleplay,
			},
		];

		return (
			<div className="footer__app-links-wrapper">
				{appsLinksData.map(({linkId, href}) => href ? (
					<KeydownEnterLink
						key={linkId}
						type="plain"
						style={{backgroundImage: `url('/img/badge/app/${linkId}.svg')`}}
						className="footer__badge footer__badge--app-link"
						href={href}
						target="_blank"
						rel="noopener noreferrer"
						onClick={() => setYaReachGoal(`${linkId}-click`)}
					/>
				) : null)}
			</div>
		);
	}

	render() {
		const {
			cinemaID,
			cinema: {
				payment,
				work_hours: workHours,
			},
			socials,
			phones,
			phonesWithInfo,
			isSemiblind,
			isSeveralCinemasInCity,
			cinemaOpeningHours,
			cinemaEmail,
			isArman3D,
			isKinokassa,
		} = this.props;

		return (
			<footer className="footer">
				<div className="container footer__container footer__container--top">
					<div className="footer__logo-controls-section">
						<img className="footer__logo" src="/img/logo.svg" alt={i18n.t('Logo')} />
						{this.renderAppsLinks()}
					</div>
				</div>
				<div className="container footer__container">
					<div className="footer__main-section">
						<div className="footer__column">
							{isFondKino && (
								<img className="footer__logo" src="/img/logo.svg" alt={i18n.t('Logo')} />
							)}
							<h2 className="footer__column-title">{i18n.t('Menu')}</h2>
							<div className="footer__links">
								{this.renderMenuLinks()}
							</div>
						</div>
						<div className="footer__column footer__column--double-space">
							{payment.active && this.renderPaymentInfoSection()}
							{!_.isEmpty(socials) && this.renderSocialLinks(socials)}
							{cinemaID === KINOGRADVIDNOE_CINEMA_ID && (
								<a
									className="footer__links-item"
									href="/resources/rules.pdf"
									rel="noopener noreferrer"
									download
									style={{
										display: 'inline-block',
										marginTop: '16px',
									}}
								>
									{i18n.t('RulesOfCinema')}
								</a>
							)}
						</div>
						<div className="footer__column">
							<div className="footer__contacts-wrapper">
								{this.renderCityTitle()}
								{isSeveralCinemasInCity && !isKinokassa ? (
									this.renderSeveralCinemasLinks()
								) : (
									<React.Fragment>
										{this.renderCinemaAddress()}
										{
											phonesWithInfo.length
												? this.renderPhoneNumbersWithInfo(phonesWithInfo, isArman3D)
												: phones && phones.length > 0 && this.renderPhoneNumbers(phones, isArman3D)
										}
										{
											cinemaEmail
												? (
													<div className='footer__contact-item'>
														<KeydownEnterLink
															type="plain"
															className="footer__links-item"
															href={`mailto:${cinemaEmail}`}
															onClick={() => setYaReachGoal('email-click')}
														>
															<span className="footer__email-value">{cinemaEmail}</span>
														</KeydownEnterLink>
														<span className="footer__contact-comment">{i18n.t("Email")}</span>
													</div>
												) : null
										}
										{
											cinemaOpeningHours || !_.isEmpty(workHours) && _.values(workHours).every(item => item)
												? (
													<div className="footer__work-time-wrapper">
														<h2 className="footer__column-title footer__column-title--worktime">{i18n.t('OpenHours')}</h2>
														<div className="footer__worktime">
															<WorkTime time={workHours} cinemaID={cinemaID} openingHours={cinemaOpeningHours} />
														</div>
													</div>
												)
												: null
										}
										<span className="footer__address-mall" />
									</React.Fragment>
								)}
								<span className="footer__address-mall" />
							</div>
							<div style={{display: 'flex'}}>
								<span className="footer__custom-1" />
								<span className="footer__custom-2" />
							</div>
						</div>
					</div>
					<div className="footer__bottom-section">
						<div className="footer__copyright-wrapper">
							<span className="footer__copyright">© {new Date().getFullYear()}. {i18n.t('AllRightsReserved')}</span>
							<span>
								{`${i18n.t('DevelopedAt')} `}
								<KeydownEnterLink
									type="plain"
									className="footer__links-item"
									href="https://kinoplan.ru/"
									target="_blank"
									rel="noopener noreferrer"
								>
									{i18n.t('Kinoplane')}
								</KeydownEnterLink>
							</span>
						</div>
						{!isSemiblind && (
							<KeydownEnterButton
								className="footer__view-button view-button"
								onClick={this.handleSemiblindClick}
							>
								<img src="/img/view.svg" alt="" /> {i18n.t('VersionForTheVisuallyImpaired')}
							</KeydownEnterButton>
						)}
					</div>
					{cinemaID !== AVRORA_CINEMA_ID
						? <p className="footer__ads-public-notice">
							{i18n.t('StartOfAdvertisingBlock')}.<br/>
							{i18n.t('CheckDirectionAtCinema')}.
						</p>
						: null
					}
				</div>
			</footer>
		);
	}
}
