import React from 'react';
import PropTypes from 'prop-types';
import {closeIcon} from 'app/constants/IconsConstants';
import {ClickOnKeyPressWrap, isEscPress} from 'app/utils/KeyboardHelpers';

export default class PopupComponent extends React.Component {
	static propTypes = {
		popupContentNode: PropTypes.node,
		hidePopup: PropTypes.func.isRequired,
		children: PropTypes.node,
		withCloseButton: PropTypes.bool,
		onClosePopup: PropTypes.func
	}

	handleClose = () => {
		const {hidePopup, onClosePopup} = this.props;

		hidePopup();

		if (onClosePopup) onClosePopup();
	}

	render() {
		const {popupContentNode, withCloseButton, children} = this.props;

		return (
			<div className="popup-container" key={popupContentNode}>
				<ClickOnKeyPressWrap clickCondition={isEscPress}>
					<div className="popup-overlay" onClick={this.handleClose}>
						{withCloseButton &&
							<div className="popup__exit">
								{closeIcon}
							</div>
						}
					</div>
				</ClickOnKeyPressWrap>
				<div className="popup">
					{popupContentNode ||
						<div className="popup__content">
							<div className="popup__content__body">
								{children}
							</div>
						</div>
					}
				</div>
			</div>
		);
	}
}
