import { Redirect, Route, RouteComponentProps, Switch, useRouteMatch } from 'react-router-dom';
import React, { lazy, useMemo } from 'react';
import ProcultureAfishaPageComponent from 'app/components/ProcultureAfishaPageComponent';
import MonitorImaxPageComponent from 'app/components/Monitor/MonitorImaxPageComponent';
import MonitorVipPageComponent from 'app/components/Monitor/MonitorVipPageComponent';
import { loadComponent } from 'app/selectors/Helpers';
import { Props } from 'app/containers/AppContainer';
import NotMovieAfishaPageComponent from 'app/components/NotMovieAfishaPageComponent';

const Afisha = loadComponent(lazy(() => import('app/containers/AfishaContainer')));
const AboutCinema = loadComponent(lazy(() => import('app/containers/CinemaContainer')));
const DailySeances = loadComponent(lazy(() => import('app/containers/DailySeancesContainer')));
const ReleasePage = loadComponent(lazy(() => import('app/containers/ReleasePageContainer')));
const News = loadComponent(lazy(() => import('app/containers/NewsContainer')));
const NewsItem = loadComponent(lazy(() => import('app/containers/NewsItemContainer')));
const Promo = loadComponent(lazy(() => import('app/containers/PromoContainer')));
const PromoItemContainer = loadComponent(lazy(() => import('app/containers/PromoItemContainer')));
const Soon = loadComponent(lazy(() => import('app/containers/ReleasesSoonPageContainer')));
const CustomPage = loadComponent(lazy(() => import('app/containers/CustomPageContainer')));
const CityCinemas = loadComponent(lazy(() => import('app/containers/CityCinemas/CityCinemasContainer')));
const Contacts = loadComponent(lazy(() => import('app/containers/ContactsContainer')));
const GosuslugiComponent = loadComponent(lazy(() => import('app/components/additionalModules/GosuslugiComponent')));
const StaticAdditionalPageComponent = loadComponent(
	lazy(() => import('app/components/additionalModules/StaticAdditionalPageComponent'))
);

export const AppRoutes = (props: RouteComponentProps & Props) => {
	const { cities, cityId, isMonitor } = props;

	return cities.length > 1 && !isMonitor
		? (
			<Switch>
				<Route
					path="/:cityId"
					render={() => (<Routes {...props} />)}
				/>
				<Redirect from="/" to={`/${cityId}`} />
			</Switch>
		)
		: <Routes {...props} />;
};

const Routes = ({
	cinema,
	hasAboutCinema,
	soonPageIsEnabled,
	customPagesRoutes,
	isSeveralCinemasInCity,
	isMonitor,
	sectionDisplaySettings: { imax, vip },
	isAllPromosEnabled,
	cities,
	hasProCultureSeanses,
}: RouteComponentProps & Props) => {
	const { path } = useRouteMatch();
	const promosEnabled = isAllPromosEnabled || (cinema.modules && cinema.modules.promotions_enabled);
	const addedPath = cities.length > 1 && !isMonitor ? path : '';

	const cinemaRoutes = useMemo(() => (
		window.kinosite.routes?.map((config, key) => {
			return <Route {...config} key={key} />
		}) || []
	), []);

	return (
		<Switch>
			<Route path={`${addedPath}/release/:id/:modifier?`} component={ReleasePage} />
			<Route path={`${addedPath}/seances`} render={() => <DailySeances page="seances"/>} />
			{hasProCultureSeanses ?
				<Route
					path={`${addedPath}/pushkin_card`}
					component={() => <ProcultureAfishaPageComponent addedPath={addedPath} />}
				/> : null}	
			<Route
				path={`${addedPath}/not_movies`}
				component={() => <NotMovieAfishaPageComponent addedPath={addedPath} />}
			/>

			{soonPageIsEnabled ?
				<Route exact path={`${addedPath}/soon`} component={Soon} />
				: null}
			{promosEnabled ?
				<Route
					exact
					path={`${addedPath}/promo`}
					render={(routeProps) => <Promo {...routeProps} page="promo" />}
				/>
				: null}
			{promosEnabled ?
				<Route
					exact
					path={`${addedPath}/promo/:id`}
					render={(routeProps) => <PromoItemContainer {...routeProps} page="promo-item" />}
				/>
				: null}
			{cinema.modules && cinema.modules.articles_enabled ?
				<Route
					path={`${addedPath}/news`}
					exact
					render={(routeProps) => <News {...routeProps} page="news" />}
				/>
				: null}
			{cinema.modules && cinema.modules.articles_enabled ?
				<Route
					path={`${addedPath}/news/:id`}
					render={(routeProps) => <NewsItem {...routeProps} page="news-item" />}
				/>
				: null}
			{hasAboutCinema ?
				<Route path={`${addedPath}/cinema`} component={AboutCinema} />
				: null}
			{isSeveralCinemasInCity
				? <Route path={`${addedPath}/cinemas`} component={CityCinemas} />
				: null
			}
			{!isSeveralCinemasInCity
				? <Route path={`${addedPath}/contacts`} component={Contacts} />
				: null
			}
			{imax
				? <Route
					path={addedPath ? `${addedPath}/imax` : '/imax'}
					render={() => <MonitorImaxPageComponent addedPath={addedPath} />}
				/>
				: null
			}
			{vip
				? <Route
					path={addedPath ? `${addedPath}/vip` : '/vip'}
					render={() => <MonitorVipPageComponent addedPath={addedPath} />}
				/>
				: null
			}
			{customPagesRoutes.length
				? customPagesRoutes.map(page =>
					<Route
						path={addedPath + page.href}
						key={page.id}
						render={() => <CustomPage pageId={page.id} />}
					/>,
				)
				: null
			}
			{
				window.kinosite.additionalModules?.gosuslugiPage && (
					<Route path="/gosuslugi" component={GosuslugiComponent}/>
				)
			}
			{
				window.kinosite.additionalModules?.detailsPage && (
					<Route
						path="/details"
						render={
							() => (
								<StaticAdditionalPageComponent
									key="detailsPage"
									getData={window.kinosite.additionalModules?.detailsPage?.getData as any}
									title={i18n.t('Requisites')}
								/>
							)
						}/>
				)
			}
			{
				window.kinosite.additionalModules?.saleRulesPage && (
					<Route
						path="/sale-rules"
						render={
							() => (
								<StaticAdditionalPageComponent
									key="saleRulesPage"
									getData={window.kinosite.additionalModules?.saleRulesPage?.getData as any}
									title={i18n.t('PaymentTerms')}
								/>
							)
						}/>
				)
			}
			{...cinemaRoutes}
			<Route component={Afisha} />
		</Switch>
	);
};
