import React from 'react';

export enum NoSeansesReason {
	NO_SEANCES_FOR_SELECTED_CINEMA = "NO_SEANCES_FOR_SELECTED_CINEMA",
	DEFAULT = "DEFAULT"
}

const NoSeansesComponent = ({type = NoSeansesReason.DEFAULT} : {type?: NoSeansesReason}) => {
	let message = i18n.t('SeancesForTodayAreMissing');
	let image = "/img/no-seanses.svg";

	switch (type) {
		case NoSeansesReason.NO_SEANCES_FOR_SELECTED_CINEMA:
			message = i18n.t('SeancesForSelectedCinemaAreMissing');
			break;
	}

	return (
		<div className="no-seanses">
			<img src={image} alt={message} />
			<p className="no-seanses__title">{message}</p>
		</div>
	)
};

export default NoSeansesComponent;
