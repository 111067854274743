import React from 'react';
import PropTypes from 'prop-types';
import KeydownEnterLink from 'app/components/KeydownEnterLinkComponent';
import {arrowIcon} from 'app/constants/IconsConstants';
import ReleaseItem from 'app/containers/ReleaseItemContainer';
import {scrollToTop, setYaReachGoal} from 'app/selectors/Helpers';

export default class ReleasesSoonComponent extends React.Component {
	static propTypes = {
		getReleasesSoon: PropTypes.func.isRequired,
		releasesSoon: PropTypes.arrayOf(PropTypes.object).isRequired,
		resetReleaseItemState: PropTypes.func.isRequired,
		soonPageIsEnabled: PropTypes.bool.isRequired,
		page: PropTypes.string.isRequired,
		isReleasesSoonPage: PropTypes.bool,
		cityId: PropTypes.number,
		prefix: PropTypes.string.isRequired
	};

	static defaultProps = {
		isReleasesSoonPage: false
	};

	componentDidMount() {
		const {getReleasesSoon, releasesSoon, cityId} = this.props;

		if (!releasesSoon.length) {
			getReleasesSoon(cityId);
		}
		scrollToTop();
	}

	componentWillUnmount() {
		this.props.resetReleaseItemState();
	}

	render() {
		const {releasesSoon, isReleasesSoonPage, soonPageIsEnabled, page, prefix} = this.props;

		return (
			releasesSoon.length ? (
				<div>
					<div
						id="releasesSoon"
						className={`releases-soon ${isReleasesSoonPage && soonPageIsEnabled ? '' : 'releases-soon__mini'}`}
					>
						<div className="container releases-soon--title">
							<h2 className="releases-list__title">{i18n.t('SoonAtCinemas')}</h2>
							{!isReleasesSoonPage && soonPageIsEnabled &&
								<div className="releases-soon--title__container">
									<KeydownEnterLink
										to={`${prefix}/soon`}
										className="releases-soon--title__link"
										onClick={() => setYaReachGoal(`${page}-showall-click`, null, false)}
									>
										{i18n.t('ShowAll')}
									</KeydownEnterLink>
									{arrowIcon}
								</div>
							}
						</div>
						<div className="container releases-container">
							{releasesSoon.map((release, index) =>
								<ReleaseItem release={release} key={index} soon page={page} />,
							)} 
						</div>
					</div>
				</div>
			) : null
		);
	}
}
