import {createSelector} from 'reselect';
import {getReleaseId} from 'app/selectors/ReleaseSelectors';
import {isMobile, getSoonPageIsEnabled} from 'app/selectors/AppSelectors';
import {MOBILE_POSTERS_AMOUNT, DESKTOP_POSTERS_AMOUNT} from 'app/constants/AppConstants';

export const getAllReleasesSoon = state => state.releasesSoonReducer.releasesSoon;
export const getIsFetching = state => state.releasesSoonReducer.isFetching;
const getCurrentReleasesSoon = createSelector(
	[getAllReleasesSoon, getReleaseId],
	(releasesSoon, releaseId) => _.reject(releasesSoon, (release) =>
	release.id === releaseId),
);

export const getReleasesSoonSelector = state => {
	const postersAmount = isMobile() ? MOBILE_POSTERS_AMOUNT : DESKTOP_POSTERS_AMOUNT;

	return getSoonPageIsEnabled(state)
		? _.first(getCurrentReleasesSoon(state), postersAmount)
		: getCurrentReleasesSoon(state);
};
