export const CINEMA_ID = window.kinositeSettings.cinemaId;
export const CITY_ID = window.kinositeSettings.cityId;
export const TOKEN = window.kinositeSettings.token;
export const KINOKASSA_API_URL = window.kinokassaApiUrl;

export const RAMBLER_WIDGET_KEY = window.kinositeSettings.ramblerWidgetKey;

// если в раздел "Новости" приходят не новости...
export const getSectionName = () => window.kinositeSettings.customNewsSectionName || i18n.t('News');

// часто встречающаяся хотелка: переименовывание раздела "Кинотеатр" во что-нибудь своё
export const getAboutName = () => window.kinositeSettings.customAboutSectionName || i18n.t('Cinema');

export const BUSINESS_DAY_END = 7;

export const THIRTY_MINUTES_INTERVAL = 1800000;

export const SHARED_METRICS_ID = 94570834;
