import React, { memo, useCallback } from 'react';
import cn from 'classnames';
import SeanceItem from 'app/containers/SeanceItemContainer';
import KeydownEnterLink from 'app/components/KeydownEnterLinkComponent';
import {setYaReachGoal} from 'app/selectors/Helpers';
import {getBusinessDate} from 'app/selectors/AppSelectors';
import { ReleaseProps } from 'app/containers/ReleaseItemContainer';
import ReleasePoster from 'app/components/ReleasePoster';

function ReleaseItem({
	release,
	isPremiere,
	isProCulture,
	isClosedShow,
	isStellarPremiere,
	isSpecialShow,
	isMobile,
	currentCityTitle,
	isSeveralCinemasInCity,
	prefix,
	selectedDay,
	updateSelectedDay,
	soon = false,
	today = false,
	page = null,
	isImax = false,
	isVip = false
}: ReleaseProps) {
	const {
		id: releaseId,
		rambler_id: ramblerReleaseId,
		age_rating: ageRating,
		cinema_next_date: cinemaNextDate,
		repertoire_start_date: startDate,
		seances,
		poster,
		thumbnail,
		title,
	} = release;
	const gradientClass = `gradient_${_.random(1, 8)}`;
	const backgroundPoster = isMobile || soon ? thumbnail || poster : poster || thumbnail;
	const soonStartDate = cinemaNextDate || startDate;
	const momentCinemaNextDate = soon ? moment(soonStartDate) : moment(cinemaNextDate);

	const handleReleaseClick = useCallback(() => {
		if (today && typeof updateSelectedDay === 'function') {
			updateSelectedDay(moment());
		}

		setYaReachGoal('open-release', {
			section: page,
			movie: title,
			release_id: releaseId,
			city: currentCityTitle,
		});
	}, [today, page, currentCityTitle, releaseId, title])

	const handleButtonClick = () => {
		if (typeof updateSelectedDay === 'function') {
			updateSelectedDay(moment(cinemaNextDate));
		}
	}

	const renderBadges = () => {
		const {age_rating: ageRating, genres} = release;

		return (
			<div className="releases-item-description__badge">
				{isMobile && ageRating ? <span>{ageRating}</span> : null}
				{genres.length ? genres.map(({title}, index) => <span key={index}>{title}</span>) : null}
			</div>
		);
	}

	return (
		<KeydownEnterLink
			to={`${prefix}/release/${releaseId}${isImax ? '/imax' : ''}${isVip ? '/vip' : ''}${
				selectedDay ? `?date=${selectedDay.format('YYYY-MM-DD')}` : ''
			}`}
			className={cn('releases-item', {'releases-item_soon': soon})}
			onClick={handleReleaseClick}
		>
			<>
				<div className={`releases-item__poster ${soon ? 'releases-item__poster_soon' : ''} ${gradientClass}`}>
					<div className="releases-item__poster-height" />
					{!!backgroundPoster &&
						<img
							data-src={backgroundPoster}
							className="lazyload releases-item__poster-img"
							alt={`${i18n.t('ReleasePoster')} ${title}`}
						/>
					}
					<div className="releases-item__age">{ageRating}</div>
					<div className="releases-item__badge releases-item__badge--desktop">
						<ReleasePoster
							isPremiere={isPremiere}
							isClosedShow={isClosedShow}
							isStellarPremiere={isStellarPremiere}
							isSpecialShow={isSpecialShow}
						/>
					</div>
					{!!cinemaNextDate && soon &&
						<div className="releases-item__onsale releases-item__onsale-poster">{i18n.t('TicketsAtSold').toUpperCase()}</div>
					}
					{isProCulture && !soon
						? <div className="releases-item__proculture">
							<img src="/img/pushkin.png" alt="pushkin_card" />
						</div>
						: null
					}
				</div>
				{soon ?
					<div className="releases-item__info">
						<div className="releases-item-description">
							{title && <div
								className="releases-item-description__title releases-item-description__title_small"
							>
								{title}
							</div>}
							{renderBadges()}
						</div>
						<div className="releases-item__date-wrapper">
							<span className="releases-item__date">
								{i18n.t('from')} {momentCinemaNextDate.format('D MMMM YYYY')}
							</span>
						</div>
					</div>
				:
					<div className="releases-item__info">
						<div className="releases-item-description">
							{title && <div className="releases-item-description__title">{title}</div>}
							<div className="releases-item__badge releases-item__badge--mobile">
								<ReleasePoster
									isPremiere={isPremiere}
									isClosedShow={isClosedShow}
									isStellarPremiere={isStellarPremiere}
									isSpecialShow={isSpecialShow}
								/>
								{isProCulture && !soon
									? (
										<div className="releases-item__proculture releases-item__proculture--mobile">
											{i18n.t('PushkinCard')}
										</div>
									) : null}
							</div>
							{renderBadges()}
						</div>
						{!isSeveralCinemasInCity &&
							<div className="releases-item-schedule">
								{seances.length ?
									seances.map(seance =>
										<SeanceItem
											seance={seance}
											releaseId={releaseId}
											ramblerReleaseId={ramblerReleaseId}
											key={seance.id}
											page={page ?? ''}
										/>,
									)
								: (
									<button
										className="seance-item time-tooltip"
										onClick={handleButtonClick}
									>
										{`${i18n.t('Seances')} ${i18n.t('on')} ${getBusinessDate(momentCinemaNextDate).calendar()}`}
									</button>
								)}
							</div>
						}
					</div>
				}
			</>
		</KeydownEnterLink>
	);
}

export default memo(ReleaseItem)
