import {connect} from 'react-redux';
import {getCurrentCityTitle, getCurrentCityId} from 'app/selectors/AppSelectors';
import {showPopup, hidePopup} from 'app/actions/AppActions';
import CitySelectComponent from 'app/components/CitySelect/CitySelectComponent';

const mapStateToProps = state => ({
	currentCityTitle: getCurrentCityTitle(state),
	hasCurrentCityId: !!getCurrentCityId(state)
});

export default connect(mapStateToProps, {showPopup, hidePopup})(CitySelectComponent);
