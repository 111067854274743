import React from 'react';
import { Redirect, Route, RouteComponentProps, Switch, withRouter } from 'react-router-dom';
import AfishaContainer from 'app/containers/AfishaContainer';
import NewsContainer from 'app/containers/NewsContainer';

interface IProps extends RouteComponentProps { addedPath: string; }

const MonitorVipPageComponent = ({ addedPath }: IProps) => {
	const path = addedPath || '';

	return (
		<Switch>
			<Redirect exact from={`${path}/vip`} to={`${path}/vip/afisha`} />
			<Route path={`${path}/vip/afisha`}>
				<AfishaContainer isVip />
			</Route>
			<Route path={`${path}/vip/news`}>
				<NewsContainer isVip />
			</Route>
		</Switch>
	);
};

export default withRouter(MonitorVipPageComponent);
