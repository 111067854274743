import React, { memo } from "react";
import getReleaseQualifiersContants from "app/constants/ReleaseConstants";

interface IProps {
	isPremiere: boolean,
	isClosedShow: boolean,
	isStellarPremiere: boolean,
	isSpecialShow: boolean,
}

function ReleasePoster({isPremiere, isClosedShow, isStellarPremiere, isSpecialShow}: IProps) {
	const releaseQualifiersContants = getReleaseQualifiersContants();
	const qualifiers = {
		isPremiere: ![isClosedShow, isStellarPremiere, isSpecialShow].includes(true) && isPremiere,
		isClosedShow,
		isStellarPremiere,
		isSpecialShow,
	};

	return (
		<>
			{Object.keys(releaseQualifiersContants).map(key => qualifiers[key]
				? <div key={key} className="releases-item__premiere">
					{releaseQualifiersContants[key]}
				</div>
			: null)}
		</>
	);
}

export default memo(ReleasePoster);
