import {connect} from 'react-redux';
import {updateSelectedDay} from 'app/actions/AppActions';
import ReleaseItemComponent from 'app/components/ReleaseItemComponent';
import {getHasSpecificReleaseItemQualifier} from 'app/selectors/ReleaseSelectors';
import {
	isMobileSelector,
	getCinema,
	getIsSeveralCinemasInCity, getRoutePrefix, getSelectedDay,
} from 'app/selectors/AppSelectors';
import { Moment } from 'moment';
import { IRelease } from 'app/types/releases';

const mapStateToProps = (state, {release}) => ({
	isPremiere: getHasSpecificReleaseItemQualifier(release, 'premiere'),
	isProCulture: getHasSpecificReleaseItemQualifier(release, 'pro_culture'),
	isClosedShow: getHasSpecificReleaseItemQualifier(release, 'closed_show'),
	isStellarPremiere: getHasSpecificReleaseItemQualifier(release, 'stellar_premiere'),
	isSpecialShow: getHasSpecificReleaseItemQualifier(release, 'special_show'),
	isMobile: isMobileSelector(state),
	currentCityTitle: getCinema(state).city,
	isSeveralCinemasInCity: getIsSeveralCinemasInCity(state),
	prefix: getRoutePrefix(state),
	selectedDay: getSelectedDay(state),
});

export type ReleaseProps = ReturnType<typeof mapStateToProps> & {
	release: IRelease,
	isPremiere?: boolean,
	isProCulture?: boolean,
	isClosedShow?: boolean,
	isStellarPremiere?: boolean,
	isSpecialShow?: boolean,
	isMobile?: boolean,
	currentCityTitle?: string,
	isSeveralCinemasInCity?: boolean,
	prefix?: string,
	selectedDay?: Moment | null,
	updateSelectedDay?: (day: string | Moment) => void,
	soon?: boolean,
	today?: boolean,
	page?: string | null,
	isImax?: boolean,
	isVip?: boolean,
}

export default connect(mapStateToProps, {updateSelectedDay})(ReleaseItemComponent);
