import { getErrorsCodeConvention } from 'app/selectors/AppSelectors';
import { useEffect, ReactNode } from 'react';
import Button from 'app/ui/Button';
import { AppErrorCode } from 'app/types/appReducer';

type IProps = { children: ReactNode | ReactNode[] } & {
	setError: (code: number | null) => void;
	errorCode: AppErrorCode | null;
	address: {address: string, place: string, maps: string},
	isPageNoop?: boolean;
	currentCityTitle?: string,
};

function ErrorBoundary({
	children,
	setError,
	errorCode,
	currentCityTitle,
	address,
	isPageNoop = true,
}: IProps) {
	useEffect(() => {
		if (errorCode) window.parent.postMessage({ action: 'ErrorPaymentLoaded' }, '*');
	}, [errorCode]);

	useEffect(() => () => {
		setError(null);
	}, []);

	const appError = errorCode ? getErrorsCodeConvention(errorCode) : null;

	return (
		<>
			{errorCode ?
				<div className={`noop noop-error ${isPageNoop ? 'noop--short' : ''}`}>
					<main className="noop-container">
						<div className="noop__icon-error">
							<img src={appError?.img || '/img/cone.svg'} alt="Ошибка" />
						</div>
						<div className="noop__error">
							<div className="text text--large noop__error-text">{appError?.text}</div>
							<div className="text noop__error-description">
								{appError?.description || i18n.t('ServiceUnavaliable')}
								<br />
								{errorCode === 332 && `по адресу: г. ${currentCityTitle}, ${address}`}
							</div>
							<Button
								onClick={() => window.location.reload()}
								kind={isPageNoop ? 'secondary' : 'transparent'}
							>
								{i18n.t('UpdatePage')}
							</Button>
						</div>
					</main>
					{!isPageNoop && <footer className="noop-footer">
						<span>
							© 2023. {i18n.t('AllRightsReserved')}.
							&nbsp;
							{i18n.t('DevelopedAt')}
							&nbsp;
							<a
								href="https://kinoplan.ru/"
								target="_blank"
								rel="noopener noreferrer"
							>
								Кинопланe
							</a>
						</span>
						<span>
							{i18n.t('TechnicalSupport')}:
							&nbsp;
							<a href="tel:+74959801727">+7 495 9801727 доб. 220</a>
						</span>
					</footer>}
				</div>
				: children}
		</>
	);
}

export default ErrorBoundary;
