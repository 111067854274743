import {connect} from 'react-redux';
import ViewComponent from 'app/components/ViewComponent';
import {getFontSize} from 'app/selectors/AppSelectors';
import {setSemiblind, setFontSize} from 'app/actions/AppActions';

const mapStateToProps = state => ({
	fontSize: getFontSize(state)
});

export default connect(mapStateToProps, {setSemiblind, setFontSize})(ViewComponent);
