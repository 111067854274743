import { createSelector } from 'reselect';
import { IReduxState } from 'app/types/state';

export const getAfishaSelector = (state: IReduxState) => state.afishaReducer.afisha;
export const getSeanceDisplaySettings = (state: IReduxState) => state.afishaReducer.seanceDisplaySettings;
export const getIsFetching = (state: IReduxState) =>
	state.afishaReducer.isAfishaFetching || state.afishaReducer.isAfishaInfoFetching;
export const getSeanceDates = (state: IReduxState) => state.afishaReducer.calendar.seance_dates || [];
export const getAfishaErrorCode = (state: IReduxState) => state.afishaReducer.error.code;
const getQualifiers = (state: IReduxState) => state.afishaReducer.all_qualifiers;

export const getProCultureAfisha = createSelector(
	[getAfishaSelector],
	(afisha) => {
		return afisha.filter(release => release.all_qualifiers.includes('pro_culture'));
	},
);

export const getAfishaToday = createSelector(
	getAfishaSelector,
	(afisha) => _.filter(afisha, (release) => !!release.seances.length),
);

export const hasProCultureSeanses = createSelector(
	[getQualifiers],
	(qualifiers) => !!qualifiers.includes('pro_culture'),
);
