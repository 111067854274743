import {connect} from 'react-redux';
import {hidePopup, setCityFilter, saveNewCurrentCityId, setSelectedDay} from 'app/actions/AppActions';
import {getFilteredCities} from 'app/selectors/AppSelectors';
import CitiesListSelectComponent from 'app/components/CitySelect/CitiesListSelectComponent';

const mapStateToProps = state => ({
	citiesData: getFilteredCities(state)
});

export default connect(
	mapStateToProps,
	{hidePopup, setCityFilter, saveNewCurrentCityId, setSelectedDay},
)(CitiesListSelectComponent);
