import React from 'react';
import PropTypes from 'prop-types';
import ReleaseItem from 'app/containers/ReleaseItemContainer';
import ReleasesSoon from 'app/containers/ReleasesSoonContainer';
import KeydownEnterButton from 'app/components/KeydownEnterButtonComponent';
import NewsList from 'app/components/NewsListComponent';
import Spinner from 'app/components/SpinnerComponent';
import {setYaReachGoal} from 'app/selectors/Helpers';
import {COUNT_NEWS_PAGE} from 'app/constants/AppConstants';
import {changePageTitle} from 'app/actions/AppActions';
import {getSectionName, CINEMA_ID} from '../constants/SettingsConstants';

export default class NewsComponent extends React.Component {
	static propTypes = {
		cinemaId: PropTypes.number,
		cityId: PropTypes.number.isRequired,
		totalCountNews: PropTypes.number.isRequired,
		offsetNews: PropTypes.number.isRequired,
		isFetching: PropTypes.bool.isRequired,
		getAfisha: PropTypes.func.isRequired,
		getNews: PropTypes.func.isRequired,
		resetNews: PropTypes.func.isRequired,
		afisha: PropTypes.arrayOf(PropTypes.object).isRequired,
		news: PropTypes.arrayOf(PropTypes.object).isRequired,
		match: PropTypes.shape({
			params: PropTypes.shape({
				cinemaId: PropTypes.string
			})
		}),
		isVip: PropTypes.bool,
		isImax: PropTypes.bool,
		prefix: PropTypes.string.isRequired,
		page: PropTypes.string
	}

	static defaultProps = {
		isVip: false,
		isImax: false
	}

	componentDidMount() {
		const {getAfisha, cinemaId, news, getNews, resetNews, offsetNews, cityId, match: {params}, isImax, isVip} = this.props;

		if (!CINEMA_ID && news.length) {
			resetNews();
		}

		if (!params.cinemaId) {
			getAfisha(moment().format('YYYY-MM-DD'), cityId, {isImax, isVip});
		}
		getNews(params.cinemaId || cinemaId, cityId, offsetNews, {isImax, isVip});
		changePageTitle(getSectionName());
	}

	handleLoadMoreNews = () => {
		const {cinemaId, cityId, getNews, offsetNews, match: {params}, isImax, isVip} = this.props;
		const offset = offsetNews + COUNT_NEWS_PAGE;

		getNews(params.cinemaId || cinemaId, cityId, offset, {isImax, isVip});

		setYaReachGoal('news-showmore-click', null, false);
	}

	render() {
		const {afisha, news, isFetching, totalCountNews, match: {params}, prefix, page} = this.props;
		const hasMoreNews = totalCountNews !== news.length;

		return (
			<div className="main">
				<div className="news">
					<div className="container news-container">
						<div className="news-list-wrapper">
							<div className="news__title">{getSectionName()}</div>

							{!isFetching ?
								<div className="news-list-wrapper">
									{news.length ?
										<div className="news-list">
											<NewsList news={news} prefix={prefix} page={page} />
											{hasMoreNews && (
												<KeydownEnterButton
													className="news-list__more"
													onClick={this.handleLoadMoreNews}
												>
													{i18n.t('ShowMore')}
												</KeydownEnterButton>
											)}
										</div>
										:
										<p>{i18n.t('NewsAreMissing')}</p>
									}
								</div>
								:
								<Spinner />
							}
						</div>
						{afisha.length && !params.cinemaId ?
							<div className="cinema-releases cinema-releases--news-list">
								<div className="cinema-releases__title">{i18n.t('TodayAtCinema')}</div>
								{afisha.map((release, index) => (
									index < 2 ?
										<ReleaseItem release={release} key={index} page={page} />
										: null
								))}
							</div>
							: null}
					</div>
				</div>
				{!params.cinemaId && <ReleasesSoon page={page} />}
			</div>
		);
	}
}
