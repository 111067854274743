import {createAction} from 'redux-actions';
import httpRequestPromise from 'app/httpRequestPromise';
import * as actionsTypes from 'app/constants/MenuConstants';
import {KINOKASSA_API_URL} from 'app/constants/SettingsConstants';

const setPageIsFetching = createAction(
	actionsTypes.SET_PAGE_IS_FETCHING,
);

const setCustomPages = createAction(
	actionsTypes.SET_CUSTOM_PAGES,
	payload => (payload),
);

const setCustomPageContent = createAction(
	actionsTypes.SET_CUSTOM_PAGE_CONTENT,
	payload => (payload),
);

export const resetPages = createAction(
	actionsTypes.RESET_PAGES,
);

export const getCustomPagesRequest = (cinemaId, cityId) => async dispatch => {
	try {
		const pages = await httpRequestPromise(`${KINOKASSA_API_URL}/api/v2/pages?${
            cinemaId ? `cinema_id=${cinemaId}` : `city_id=${cityId}`
        }`, 'GET');

		dispatch(setCustomPages(pages.map((page) => ({...page, isCustomCinemaLink: true}))));
	} catch (error) {
		dispatch(setCustomPages([]));
	}
};

export const getCustomPageContent = (cinemaId, pageId) => async dispatch => {
	dispatch(setPageIsFetching());

	try {
		const page = await httpRequestPromise(
			`${KINOKASSA_API_URL}/api/v2/pages/${pageId}${cinemaId ? `?cinema_id=${cinemaId}` : ''}`, 'GET',
		);

		dispatch(setCustomPageContent(page));
	// eslint-disable-next-line no-empty
	} catch (error) {}
};
