import {handleActions} from 'redux-actions';
import * as actions from 'app/constants/AppConstants';

const initialState = {
	calendar: {
		seance_dates: [],
	},
	cinemas: [],
	release: {},
	error: {},
	isFetching: false,
};

export default handleActions({
	[actions.GET_RELEASE_REQUEST]: state => ({
		...state,
		isFetching: true,
		error: {},
	}),

	[actions.GET_RELEASE_SUCCESS]: (state, action) => ({
		...state,
		...action.payload,
		isFetching: false,
		error: {},
	}),

	[actions.GET_RELEASE_FAIL]: (state, action) => ({
		...state,
		isFetching: false,
		error: action.payload.error,
	}),

	[actions.RESET_RELEASE_ITEM_STATE]: state => ({
		...state,
		release: {},
	}),

}, initialState);
