import React, { useEffect, lazy } from 'react';
import { changePageTitle } from 'app/actions/AppActions';
import Menu from 'app/containers/MenuContainer';
import ReleaseItem from 'app/containers/ReleaseItemContainer';
import { arrowIcon } from 'app/constants/IconsConstants';
import KeydownEnterLink from 'app/components/KeydownEnterLinkComponent';
import Spinner from 'app/components/SpinnerComponent';
import NoSeanses from 'app/components/NoSeansesComponent';
import NewsList from 'app/components/NewsListComponent';
import { THIRTY_MINUTES_INTERVAL, getSectionName } from 'app/constants/SettingsConstants';
import { getDateFromSearch, loadComponent } from 'app/selectors/Helpers';
import { AfishaProps } from 'app/containers/AfishaContainer';
import AfishaErrorContainer from 'app/containers/errors/AfishaErrorContainer';
import { SOCHI_CINEMA_ID } from 'app/constants/CinemaConstants';

const PromoOthersComponent = loadComponent(lazy(() => import('app/components/PromoOthersComponent')));
const ReleasesSoon = loadComponent(lazy(() => import('app/containers/ReleasesSoonContainer')));
const GosuslugiAfishaComponent = loadComponent(lazy(() => import('app/components/additionalModules/GosuslugiAfishaComponent')));

const AfishaComponent = ({
	afisha,
	getAfisha,
	getAfishaInfo,
	getReleasesSoon,
	hasReleasesSoon,
	isFetching,
	seanceDates,
	selectedDay,
	cityId,
	promos,
	news,
	isVip,
	isImax,
	isProCulture,
	isNotMovie,
	prefix,
	location: {
		search,
		pathname,
	},
	setSelectedDay,
	isPlanetarium = false,
	isKinokassa,
	cinemasCount,
}: AfishaProps) => {
	const hasSeveralCinemas = cinemasCount > 1;
	const isEnabledGosuslugiOnAfisha = window.kinosite.additionalModules?.gosuslugiOnAfisha
		&& !isImax && !isVip && !isProCulture && !isNotMovie

	useEffect(() => {
		let searchDate: string | null = null;
		if (search) {
			searchDate = getDateFromSearch(search);

			if (searchDate) {
				setSelectedDay(moment(searchDate));
			}
		}
		const trueDay = searchDate ?? selectedDay?.format('YYYY-MM-DD');
		const menuItem = document.querySelector<HTMLDivElement>('.menu--date .menu__item--active');

		if (cityId && cinemasCount) {
			getAfishaInfo(cityId);
			getAfisha(trueDay, cityId, { isImax, isVip, isNotMovie, isKinokassa, hasSeveralCinemas });
			getReleasesSoon(cityId);
		}

		const intervalId = setInterval(() => {
			const date = menuItem
				? moment(menuItem.dataset.date || {}).format('YYYY-MM-DD')
				: trueDay;
			getAfishaInfo(cityId);
			getAfisha(date, cityId, { isImax, isVip, isNotMovie, isKinokassa, hasSeveralCinemas });
		}, THIRTY_MINUTES_INTERVAL);

		changePageTitle(i18n.t('Afisha'));

		return () => {
			clearInterval(intervalId);
		};
	}, [cityId, isImax, isVip, isNotMovie, hasSeveralCinemas, isKinokassa, cinemasCount]);

	const handleChangeDate = (date) => {
		getAfisha(date, cityId, { isImax, isVip, isNotMovie, isKinokassa, hasSeveralCinemas});
	};

	const page = isProCulture ? 'pushkin_card' :
		isNotMovie ? 'not_movies' :
		isVip ? 'afisha-vip' :
		isImax ? 'afisha-imax' : 'afisha';

	return (
		<AfishaErrorContainer>
			<div className="page">
				<Menu.Calendar
					isPlanetarium={isPlanetarium}
					seanceDates={seanceDates}
					onClick={handleChangeDate}
					hasReleasesScrollButton={hasReleasesSoon}
				/>
				<div className="releases-list">
					{
						isEnabledGosuslugiOnAfisha && window.kinositeSettings.cinemaId !== SOCHI_CINEMA_ID && (
							<GosuslugiAfishaComponent pathname={pathname} />
						)
					}
					{!isFetching ?
						<div className="container releases-container">
							{afisha.length ?
								afisha.map((release, index) =>
									<ReleaseItem release={release} key={index} page={page} isImax={isImax} isVip={isVip} />,
								) : (
									<NoSeanses />
								)
							}
						</div>
						:
						<Spinner />
					}
				</div>
				{isEnabledGosuslugiOnAfisha && window.kinositeSettings.cinemaId === SOCHI_CINEMA_ID && (
					<GosuslugiAfishaComponent pathname={pathname} />
				)}
				{!(isVip || isImax || isProCulture || isNotMovie) &&
					<React.Fragment>
						{!!promos.length && <PromoOthersComponent promos={promos} prefix={prefix} page={page} />}
						{!!news.length &&
							<div className="container news-container">
								<div className="news-list-wrapper">
									<div className="news-list__header">
										<div className="news__title">{getSectionName()}</div>
										<div className="promo__title-container">
											<KeydownEnterLink
												to={`${prefix}/news`}
												className="promo__title-link promo__title-link--black"
											>
												<span>{i18n.t('ShowAll')} </span>
											</KeydownEnterLink>
											{arrowIcon}
										</div>
									</div>
									<NewsList news={_.first(news, 3)} prefix={prefix} page={page}/>
								</div>
							</div>
						}
						{hasReleasesSoon && !isPlanetarium && <ReleasesSoon page={page} />}
					</React.Fragment>
				}
			</div>
		</AfishaErrorContainer>
	);
};

export default AfishaComponent;
